import React from 'react'
import { useTranslation } from 'react-i18next'

import './Information.css'
import { InformationItem } from '../../components'
const Information = () => {
  const { t } = useTranslation() 
  return (
    <div className='information'>
      <div className="container">
        <div className="title">{t('information__title')}</div>
        <div className="information__content">
          <InformationItem />
        </div>
      </div>
    </div>
  )
}

export default Information