import React, { useEffect, useState } from 'react'
import "./BasketItem.css"
import i18next from 'i18next'
import { useDispatch } from 'react-redux'
import { removeProductAction } from '../../store/redusers/basketReduser'

export default function BasketItem({id, props, data, count, onChange}){
  const dispatch = useDispatch()
  const [itemCount, setItemCount] = useState(1)
  
  function Counter(value){
    if(itemCount + value <= 0) {
      dispatch(removeProductAction(id))
    }
    else{
      setItemCount(itemCount + value)
    }
  }
  useEffect(() =>{
    onChange(id, itemCount)
  }, [itemCount])

  return (
    <div className='basket-item'>
      <div className='basket-item__header'>
        <div className='basket-item__img'>
          <img src={`${props.image}`} alt={props.title.en} />
        </div>
        <div className='basket-item__text'>
          <div className='basket-item__text'>
            <div className='basket-item__text__price'>{ (i18next.language === "ru" ? (props.price.ru * itemCount) + "₽" : (props.price.en * itemCount) + "$") } { "- x" + itemCount }</div>
            <div className='basket-item__text__title'>{ i18next.language === "ru" ? props.title.ru : props.title.en }</div>
          </div>
          <div className='basket-item__counter'>
            <button onClick={() => Counter(-1)} >-</button>
            {/* <div className='basket-item__text__count'>x{itemCount}</div> */}
            <button onClick={() => Counter(1)} >+</button>
            <button className='basket-item__text__btn-reset' onClick={() => dispatch(removeProductAction(id))} >X</button>
          </div>
        </div>
      </div>
      <div className='basket-item__data'>
        {data.color}
      </div>
    </div>
  )
}