import React from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import './Transaction.css'
import TransactionItem from '../../components/TransactionItem/TransactionItem'

import { images } from '../../constants'
const Transaction = () => {
    const { t } = useTranslation()
    const transactiomItemsData = [
        {
            id: 1,
            title: {
                ru: "Доставка по России",
                en: "Delivery of shoes, clothes and sanda"
            },
            text: {
                ru: "Доставка товара из наличия осуществляется компанией СДЭК в течение 2-5 рабочих дней. Стоимость доставки от 199 ₽.",
                en: "Delivery of these types of goods is done by China Post and EMS within 2 weeks. The cost of delivery is $15 per item."
            }
        },
        {
            id: 2,
            title: {
                ru: "Доставка из Китая",
                en: "Delivery of weapons and carpets"
            },
            text: {
                ru: "Доставка товаров не из наличия осуществляется международной почтой или сухопутными способами транспортными компаниями. Срок доставки от 2 недель до 1,5 месяцев в зависимости от товара.",
                en: "Delivery of weapons such as cudgels and spears can be done via FedEx/UPS/DHL in 1 week's time. Delivery of swords and other items is done through air freight within 2 weeks. Carpets can be delivered by container or rail transportation."
            }
        },
        {
            id: 3,
            title: {
                ru: "Оплата",
                en: "Payment"
            },
            text: {
                ru: "Доставка товара из наличия осуществляется после полной оплаты. Для товаров из Китая допускается авансовый платеж в 50% при оформлении заказа. Оставшуюся сумму вы оплачиваете после предоставления почтового трекинг-кода.",
                en: "Payment for orders is made through bank transfer in full, at the value of the order."
            }
        }
    ]
  return (
    <div className='transaction'>
        <div className="container">
            <div className="transaction__content">
                <div className="transaction__content-left">
                    <div className="title">{t('transaction__title')}</div>
                    <div className="second-title">{t('transaction__second-title')}</div>
                    <div className="transaction-items">
                        {transactiomItemsData.map((item) =>
                            <TransactionItem key={item.id} title={i18next.language === "ru" ? item.title.ru : item.title.en } text={i18next.language === "ru" ? item.text.ru : item.text.en} />
                        )}
                    </div>
                </div>
            </div>
        </div>
        <img className="transaction-img" src={images.transaction} alt="transaction img" />
    </div>
  )
}

export default Transaction