import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './reset.css';
//import 'bootstrap/dist/css/bootstrap.min.css'

import App from './App';
import { HashRouter } from 'react-router-dom';

/* localization */
import i18n from "i18next"
import { initReactI18next } from 'react-i18next';
import Languagedetector from 'i18next-browser-languagedetector';
import httpApi from 'i18next-http-backend';
import { Provider } from 'react-redux';
import { store } from './store';

i18n
.use(initReactI18next)
.use(Languagedetector)
.use(httpApi)
.init({
  supportedLngs: ['ru', 'en'],
  fallbackLng: ['ru'],
  detection: {
    order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
    caches: ['cookie']
  },
  backend: {
    loadPath: './assets/locales/{{lng}}/translation.json'
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);
