import React from 'react'
import i18next from 'i18next'

import './CatalogItem.css'
import { NavLink } from 'react-router-dom'
const CatalogItem = (props) => {
  return (
    <NavLink to={props.name} state={props} className='catalog-item'>
        <div className="image"><img src={props.image} alt="catalog-item" /></div>
        <div className="title">{i18next.language === 'ru' ? props.title.ru : props.title.en}</div>
        <div className="text__container">
          {i18next.language === 'ru' ? props.text.ru.map((item, idx) => <div className='text' key={idx}>{item}</div>) : props.text.en.map((item, idx) => <div className='text' key={idx}>{item}</div>)}
        </div>
    </NavLink>
  )
}

export default CatalogItem