import React from 'react'

import './PopularSections.css'
import { CarouselCatalog } from '../../components'
import { useTranslation } from 'react-i18next'

import { catalogD } from '../../data'

const PopularSections = () => {
  const { t } = useTranslation()

  return (
    <div className='popular-sections'>
        <div className="container">
            <div className="popular-sections__content">
                <div className="title">{t('popular-sections__title')}</div>
                <CarouselCatalog data={catalogD}/>
            </div>
        </div>
    </div>
  )
}

export default PopularSections