import vk_icon from '../assets/vk-icon.svg'
import fb_icon from '../assets/fb-icon.svg'
import instagram_icon from '../assets/instagram-icon.svg'
import logo from '../assets/logo.svg'
import basket from '../assets/shop-basket-icon.svg'
import basketBlack from '../assets/shop-basket-black-icon.svg'
import menu from '../assets/menu-icon.svg'
import close from '../assets/close-icon.svg'
import black_close from '../assets/black-close-icon.svg'
import bg_homePage from '../assets/bg__home-page.jpg'
import shopCard_icon from '../assets/shop-cart-icon.svg'
import circleChech from '../assets/circle-check.svg'
import bg__offersContainer from '../assets/bg__offers-container.png'
import about__img_1 from '../assets/about__img-1.png'
import about__img_2 from '../assets/about__img-2.jpg'
import success from '../assets/success-icon.png'

import offer_1ru from '../assets/offers/offers_1-ru.png'
import offer_1en from '../assets/offers/offers_1-en.png'
import offer_2ru from '../assets/offers/offers_2-ru.png'
import offer_2en from '../assets/offers/offers_2-en.png'
import offer_3ru from '../assets/offers/offers_3-ru.png'
import offer_3en from '../assets/offers/offers_3-en.png'
import offer_4ru from '../assets/offers/offers_4-ru.png'
import offer_4en from '../assets/offers/offers_4-en.png'

import transaction from '../assets/transaction-img.png'

import plus_1 from '../assets/plus-icon-1.svg'
import plus_2 from '../assets/plus-icon-2.svg'
import plus_3 from '../assets/plus-icon-3.svg'
import plus_4 from '../assets/plus-icon-4.svg'

import leftArrow from '../assets/left-arrow.svg'
import rightArrow from '../assets/right-arrow.svg'

import faqItem__icon from '../assets/faq-item__icon.svg'

import contact__phone from '../assets/telephone-icon.svg'
import contact_mail from '../assets/mail-icon.svg'


export default {
    vk_icon,
    fb_icon,
    instagram_icon,
    logo,
    basket,
    basketBlack,
    menu,
    close,
    black_close,
    bg_homePage,
    shopCard_icon,
    circleChech,
    bg__offersContainer,
    about__img_1,
    about__img_2,
    success,
    offer_1ru,
    offer_1en,
    offer_2ru,
    offer_2en,
    offer_3ru,
    offer_3en,
    offer_4ru,
    offer_4en,
    transaction,
    plus_1,
    plus_2,
    plus_3,
    plus_4,
    leftArrow,
    rightArrow,
    faqItem__icon,
    contact__phone,
    contact_mail
}