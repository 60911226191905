import i18next from "i18next";
import { CallBack, Goods, SecondaryHeader } from "../containers";
import { catalogD ,productsD } from "./../data";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GoodsPage = () => {
  const { t } = useTranslation()
  const props = useParams();
  const catalog = catalogD.find((item) => item.name === props.catalog) || productsD.shoes.find((item) => item.name === props.catalog);

  return (
    <>
      <SecondaryHeader
        route={"/" + t("catalog")}
        title={i18next.language === "ru" ? catalog.title.ru : catalog.title.en}
      />
      <Goods />
      <CallBack />
    </>
  );
};

export default GoodsPage;
