const baseUrl = 'https://api.telegram.org/bot6609662167:AAFtC19RWMusdyfxZdtu4WyYeCOXiDhLS74/'
const chatId = '-4150613639' //test - '-4152839157' main - '-4150613639'

export const sendMessage = async (message, images) =>
{
    await fetch(`${baseUrl}sendMessage?chat_id=${chatId}&text=${message}`)
    if(images){
        console.log(Array.isArray(images))
        if (Array.isArray(images)){
            images.map(async (img) => {
                await fetch(`${baseUrl}sendPhoto?chat_id=${chatId}&photo=${window.location.origin}/${img}`, { method: 'post' })
            })
        }
        else{
            await fetch(`${baseUrl}sendPhoto?chat_id=${chatId}&photo=${window.location.origin + images}`, { method: 'post' })
        }
    }
}