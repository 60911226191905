import React from 'react'

import { About, CallBack, SecondaryHeader } from '../containers'
import { useTranslation } from 'react-i18next'
const AboutPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <SecondaryHeader title={t("about")}/>
      <About />
      <CallBack />
    </>
  )
}

export default AboutPage