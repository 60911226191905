import React from 'react'
import './RadioInputForm.css'
const RadioInputForm = ( { onChange, title, type, props }) => {
  const handlerInputChange = (e) => {
    onChange(e)
  }
  return (
    <>
        <div className="title">{title}</div>
        <div className="inputs-radio">
          {props.map((item) => 
            <>
              <input type="radio" name={type} id={item} value={item} onChange={handlerInputChange} required />
              <label className='radio' htmlFor={item}>{item}</label>
            </>
          )}
        </div>
        <hr />
    </>
  )
}

export default RadioInputForm