import React from "react";

import "./Achievement.css";
import { AchievementItem } from "../../components";
import { useTranslation } from "react-i18next";

const Achievement = () => {
  const { t } = useTranslation();

  const achievementData = [
    {
      id: 1,
      title: "12",
      text: "achievement-1",
    },
    {
      id: 2,
      title: "1000+",
      text: "achievement-2",
    },
    {
      id: 3,
      title: "8700+",
      text: "achievement-3",
    },
    {
      id: 4,
      title: "96%",
      text: "achievement-4",
    },
  ];

  return (
    <div className="app__achievement">
      <div className="container">
        <div className="app__achievement-content">
          {achievementData.map((item) => (
            <AchievementItem
              key={item.id}
              title={item.title}
              text={t(`${item.text}`)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Achievement;
