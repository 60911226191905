import React from 'react'
import { SecondaryHeader, FAQ, CallBack } from '../containers'
import { useTranslation } from 'react-i18next'

const FAQPage = () => {
  const { t } = useTranslation()
  return (
    <>
        <SecondaryHeader title={t('faq')}/>
        <FAQ />
        <CallBack />
    </>
  )
}

export default FAQPage