import React from 'react'

import './SecondaryHeader.css'
import { useTranslation } from 'react-i18next'
import { Navigation } from '../../components'

const SecondaryHeader = ({ route, title }) => {
  const { t } = useTranslation()
  return (
    <div className='secondary-header'>
      <Navigation />
      <div className="secondary-header__content">
        <div className="container">
          <div className="secondary-header__content__wrapper">
            <div className='title'>{title}</div>
            <div className='text-route'>{t("home")} {route} <span>/ {title}</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondaryHeader