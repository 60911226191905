import React from 'react'
import { SecondaryHeader, Catalog, CallBack } from '../containers'
import { useTranslation } from 'react-i18next'

const CatalogPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <SecondaryHeader title={t("catalog")}/>
      <Catalog />
      <CallBack />
    </>
  )
}

export default CatalogPage