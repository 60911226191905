import suitsHeader from '../assets/catalog/suits-header.png'
import vipSuitsHeader from '../assets/catalog/vip-suits-header.png'
import shoesHeader from '../assets/catalog/shoes-header.png'
import weaponHeader from '../assets/catalog/weapon-header.png'
import accessoriesHeader from '../assets/catalog/accessories-header.png'
import sandaHeader from '../assets/catalog/sanda-header.png'
import clothHeader from "../assets/catalog/cloth.png"
import carpetsPlatformsHeader from "../assets/catalog/carpets-platforms-header.png"

export default{
    suitsHeader,
    vipSuitsHeader,
    shoesHeader,
    weaponHeader,
    accessoriesHeader,
    sandaHeader,
    clothHeader,
    carpetsPlatformsHeader,
}