import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import './Contacts.css'
import { sendMessage } from '../../utlis/sendMessage'
import { enableModalAction } from '../../store/redusers/completeModalReduser';

import { images } from '../../constants'
const Contacts = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [inputData, setInputData] = useState('')

  let isLoad = false;

  const handlerInputChange = (e) => {
    setInputData({...inputData, [e.target.name]: e.target.value})
  }

  const getInputData = async (e) => {
    e.preventDefault()
    if(isLoad === true) return;
    isLoad = true

    try{
      sendMessage(`Сообщение обратной связи: %0A${JSON.stringify(inputData, null, '%0A').replace(/[{}"\s]/g, ' ')}`)
    }catch(ex){
      alert(ex)
    }
    dispatch(enableModalAction())
    isLoad = false
  }


  return (
    <div className='contacts__wrapper'>
        <div className="container">
            <div className="contacts__content">
                <div className="contacts__content-left">
                  <div className="title">{t("contacts")}</div>
                  <div className="second-title">{t("contacts")} <span>/ {t("contacts")}</span></div>
                  <div className="text">{i18next.language === "ru" ? "Бесплатная консультация": "Free consultation"}</div>
                  <div className="second-text">{i18next.language === "ru" ? "Консультация абсолютно бесплатная и ни к чему вас не обязывает.": "The consultation is absolutely free and does not oblige you to anything."}</div>
                  <div className="contact-items">
                    <div className="contact-item">
                      <img src={images.contact__phone} alt="contact__phone-icon" />
                      <div className="wrapper">
                        <div className="title">{t("telephone")}</div>
                        <div className="text">{t("number")}</div>
                      </div>
                    </div>
                    <div className="contact-item">
                      <img src={images.contact_mail} alt="contact__mail-icon" />
                      <div className="wrapper">
                        <div className="title">E-mail:</div>
                        <div className="text">order@wudao-wushu.ru</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contacts__content-right">
                  <form onSubmit={getInputData}>
                    <input type="text" name='user' placeholder={t("placeholder__first-name__second-name")} required onChange={handlerInputChange} />
                    <input type="number"  name='number'placeholder={t("placeholder__phone-number")} required onChange={handlerInputChange} />
                    <input type="text"  name='text'placeholder={t("placeholder__message-text")} required onChange={handlerInputChange} />
                    <div className='text'>{t("call-back__form-send__text")}<NavLink to="/privacy-policy">{t("call-back__privacy-policy")}</NavLink></div>
                    <button className='btn'>{t("call-back__form-send__btn")}</button>
                  </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contacts