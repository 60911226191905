import React, { useState } from 'react'

import './ColorForm.css'
import i18next from 'i18next'
const ColorForm = ({ onChange, colors }) => {
  const [open, setOpen] = useState(colors.length < 20 ? true : false)

  const handlerInputChange = (e) => {
    onChange(e)
  }
  return (
    <>
        <div className="color title" onClick={() => setOpen(!open)}>
          {i18next.language === "ru" ? "Выберите цвет" : "Select color"}
        </div>
        <div className={open ? "grid-imput active" : "grid-imput"}>
          <div className="color__inputs-radio">
          {colors.map((item) => 
              <>
                  <input type="radio" name='Color' id={item.id} value={item.id} onChange={handlerInputChange} required />
                  <label className='color' htmlFor={item.id} style={ {background: `${item.color}`} } />
              </>
          )}
          </div>
        </div>
        <hr />
    </>
  )
}

export default ColorForm