import React from 'react'

import './PlusItem.css'
const PlusItem = ({ image, title, text }) => {
  return (
    <div className='plus-item'>
        <img src={image} alt="" />
        <div className="title">{title}</div>
        <p className="text">{text}</p>
    </div>
  )
}

export default PlusItem