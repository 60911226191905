import React from 'react'

import { PrivacyPolicy, SecondaryHeader } from '../containers'
const PrivacyPolicyPage = () => {
  return (
    <>
        <SecondaryHeader title="Политика конфиденциальности"/>
        <PrivacyPolicy />
    </>
  )
}

export default PrivacyPolicyPage