const tableSuits = {
  headerSuits: 
  [
    {
      Header: 
      {
        ru: "Ростовка",
        en: "Size"
      },
      accessor: "height"
    },
    {
      Header: 
      {
        ru: "Длина рубахи",
        en: "Shirt length"
      },
      accessor: "shirt-length"
    },
    {
      Header: 
      {
        ru: "Ширина рубахи в груди",
        en: "Shirt chest width"
      },
      accessor: "shirt-chest-length"
    },
    {
      Header: 
      {
        ru: "Длина брюк",
        en: "Length trousers"
      },
      accessor: "trousers-length"
    },
    {
      Header: 
      {
        ru: "Ширина брюк в бедрах",
        en: "Pants width at hips"
      },
      accessor: "trouser-length-hips"
    }
  ],
  dataSuits: 
  [
    {
      "height": 120,
      "shirt-length": 60,
      "shirt-chest-length": 90,
      "trousers-length": 93,
      "trouser-length-hips": 93
    },
    {
      "height": 125,
      "shirt-length": 62,
      "shirt-chest-length": 92,
      "trousers-length": 95,
      "trouser-length-hips": 95
    },
    {
      "height": 130,
      "shirt-length": 63,
      "shirt-chest-length": 93,
      "trousers-length": 97,
      "trouser-length-hips": 98
    },
    {
      "height": 135,
      "shirt-length": 65,
      "shirt-chest-length": 95,
      "trousers-length": 98,
      "trouser-length-hips": 98
    },
    {
      "height": 140,
      "shirt-length": 68,
      "shirt-chest-length": 96,
      "trousers-length": 102,
      "trouser-length-hips": 102
    },
    {
      "height": 145,
      "shirt-length": 71,
      "shirt-chest-length": 98,
      "trousers-length": 103,
      "trouser-length-hips": 105
    },
    {
      "height": 150,
      "shirt-length": 76,
      "shirt-chest-length": 100,
      "trousers-length": 105,
      "trouser-length-hips": 108
    },
    {
      "height": 155,
      "shirt-length": 78,
      "shirt-chest-length": 103,
      "trousers-length": 108,
      "trouser-length-hips": 113
    },
    {
      "height": 160,
      "shirt-length": 80,
      "shirt-chest-length": 110,
      "trousers-length": 110,
      "trouser-length-hips": 118
    },
    {
      "height": 165,
      "shirt-length": 84,
      "shirt-chest-length": 112,
      "trousers-length": 112,
      "trouser-length-hips": 120
    },
    {
      "height": 170,
      "shirt-length": 86,
      "shirt-chest-length": 115,
      "trousers-length": 114,
      "trouser-length-hips": 122
    },
    {
      "height": 175,
      "shirt-length": 87,
      "shirt-chest-length": 117,
      "trousers-length": 116,
      "trouser-length-hips": 123
    }
  ]
}
const tableShoes = {
  headerShoes: [
    {
      Header:
      {
        ru: "Размер обуви",
        en: "Shoe size"
      },
      accessor: "shoeSize"
    },
    {
      Header:
      {
        ru: "Длина стопы",
        en: "Foot length"
      },
      accessor: "footLength"
    }
  ],
  dataFeiyue: [
    {
      "shoeSize": 27,
      "footLength": "черные 16,5, белые 17"
    },
    {
      "shoeSize": 28,
      "footLength": 18
    },
    {
      "shoeSize": 29,
      "footLength": 19
    },
    {
      "shoeSize": 30,
      "footLength": 20
    },
    {
      "shoeSize": 31,
      "footLength": "20,5"
    },
    {
      "shoeSize": 32,
      "footLength": 21
    },
    {
      "shoeSize": 33,
      "footLength": "21,5"
    },
    {
      "shoeSize": 34,
      "footLength": 22
    },
    {
      "shoeSize": 35,
      "footLength": "22,5"
    },
    {
      "shoeSize": 36,
      "footLength": 23
    },
    {
      "shoeSize": 37,
      "footLength": "23,5"
    },
    {
      "shoeSize": 38,
      "footLength": 24
    },
    {
      "shoeSize": 39,
      "footLength": "24,5"
    },
    {
      "shoeSize": 40,
      "footLength": 25
    },
    {
      "shoeSize": 41,
      "footLength": "25,5"
    },
    {
      "shoeSize": 42,
      "footLength": 26
    },
    {
      "shoeSize": 43,
      "footLength": "26,5"
    },
    {
      "shoeSize": 44,
      "footLength": 27
    },
    {
      "shoeSize": 45,
      "footLength": "27,5"
    },
    {
      "shoeSize": 46,
      "footLength": 28
    },
    {
      "shoeSize": 47,
      "footLength": "28,5"
    }
  ],
  dataFeiyueChildren: [
    {
      "shoeSize": 25,
      "footLength": "16,5"
    },
    {
      "shoeSize": 26,
      "footLength": 17
    },
    {
      "shoeSize": 27,
      "footLength": "17,5"
    },
    {
      "shoeSize": 28,
      "footLength": 18
    },
    {
      "shoeSize": 29,
      "footLength": "18,5"
    },
    {
      "shoeSize": 30,
      "footLength": 19
    },
    {
      "shoeSize": 31,
      "footLength": "19,5"
    },
    {
      "shoeSize": 32,
      "footLength": 20
    },
    {
      "shoeSize": 33,
      "footLength": "20,5"
    },
    {
      "shoeSize": 34,
      "footLength": 21
    },
    {
      "shoeSize": 35,
      "footLength": "21,5"
    },
    {
      "shoeSize": 36,
      "footLength": 22
    }
  ],
  dataLoveWushu: [
    {
      "shoeSize": 29,
      "footLength": "19,5"
    },
    {
      "shoeSize": 30,
      "footLength": 20
    },
    {
      "shoeSize": 31,
      "footLength": "20,5"
    },
    {
      "shoeSize": 32,
      "footLength": 21
    },
    {
      "shoeSize": 33,
      "footLength": "21,5"
    },
    {
      "shoeSize": 34,
      "footLength": 22
    },
    {
      "shoeSize": 35,
      "footLength": "22,5"
    },
    {
      "shoeSize": 36,
      "footLength": 23
    },
    {
      "shoeSize": 37,
      "footLength": "23,5"
    },
    {
      "shoeSize": 38,
      "footLength": 24
    },
    {
      "shoeSize": 39,
      "footLength": "24,5"
    },
    {
      "shoeSize": 40,
      "footLength": 25
    },
    {
      "shoeSize": 41,
      "footLength": "25,5"
    },
    {
      "shoeSize": 42,
      "footLength": 26
    },
    {
      "shoeSize": 43,
      "footLength": "26,5"
    },
    {
      "shoeSize": 44,
      "footLength": 27
    }
  ],
  dataBudosaga:[
    {
      "shoeSize": 28,
      "footLength": 18
    },
    {
      "shoeSize": 29,
      "footLength": "18,5"
    },
    {
      "shoeSize": 30,
      "footLength": 19
    },
    {
      "shoeSize": 31,
      "footLength": "19,5"
    },
    {
      "shoeSize": 32,
      "footLength": 20
    },
    {
      "shoeSize": 33,
      "footLength": "20,5"
    },
    {
      "shoeSize": 34,
      "footLength": 21
    },
    {
      "shoeSize": 35,
      "footLength": 22
    },
    {
      "shoeSize": 36,
      "footLength": "22,5"
    },
    {
      "shoeSize": 37,
      "footLength": 23
    },
    {
      "shoeSize": 38,
      "footLength": "23,5"
    },
    {
      "shoeSize": 39,
      "footLength": 24
    },
    {
      "shoeSize": 40,
      "footLength": 25
    },
    {
      "shoeSize": 41,
      "footLength": "25,5"
    },
    {
      "shoeSize": 42,
      "footLength": 26
    },
    {
      "shoeSize": 43,
      "footLength": 27
    },
    {
      "shoeSize": 44,
      "footLength": "27,5"
    },
    {
      "shoeSize": 45,
      "footLength": 28
    },
    {
      "shoeSize": 46,
      "footLength": 29
    },
    {
      "shoeSize": 47,
      "footLength": 30
    }
  ],
  dataGWC:[
    {
      "shoeSize": 33,
      "footLength": "22,5"
    },
    {
      "shoeSize": 34,
      "footLength": 23
    },
    {
      "shoeSize": 35,
      "footLength": "23,5"
    },
    {
      "shoeSize": 36,
      "footLength": 24
    },
    {
      "shoeSize": 37,
      "footLength": "24,5"
    },
    {
      "shoeSize": 38,
      "footLength": 25
    },
    {
      "shoeSize": 39,
      "footLength": "25,5"
    },
    {
      "shoeSize": 40,
      "footLength": 26
    },
    {
      "shoeSize": 41,
      "footLength": "26,5"
    },
    {
      "shoeSize": 42,
      "footLength": 27
    },
    {
      "shoeSize": 43,
      "footLength": "27,5"
    },
    {
      "shoeSize": 44,
      "footLength": 28
    },
    {
      "shoeSize": 45,
      "footLength": "28,5"
    }
  ],
  dataAsics:[
    {
      "shoeSize": 22,
      "footLength": 22
    },
    {
      "shoeSize": 22.5,
      "footLength": 22.5
    },
    {
      "shoeSize": 23,
      "footLength": 23
    },
    {
      "shoeSize": 23.5,
      "footLength": 23.5
    },
    {
      "shoeSize": 24,
      "footLength": 24
    },
    {
      "shoeSize": 24.5,
      "footLength": 24.5
    },
    {
      "shoeSize": 25,
      "footLength": 25
    },
    {
      "shoeSize": 25.5,
      "footLength": 25.5
    },
    {
      "shoeSize": 26,
      "footLength": 26
    },
    {
      "shoeSize": 26.5,
      "footLength": 26.5
    },
    {
      "shoeSize": 27,
      "footLength": 27
    },
    {
      "shoeSize": 27.5,
      "footLength": 27.5
    },
    {
      "shoeSize": 28,
      "footLength": 28
    }
  ],
  
}
export { tableSuits, tableShoes }