import React from 'react'

import './MeasurementsForm.css'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
const MeasurementsForm = ({ onChange }) => {
  const { t } = useTranslation()
  const handlerInputChange = (e) => {
    onChange(e)
  }

  return (
    <>
        <div className="title">{i18next.language === "ru" ? "Укажите мерки" : "Please provide measurements"}</div>
        <div className="form__inputs">
            <input type="number" name='Height' placeholder={t("placeholder__height")} required onChange={handlerInputChange}/>
            <input type="number" name='Weight' placeholder={t("placeholder__weight")} required onChange={handlerInputChange}/>
            <input type="number" name='Сhest' placeholder={t("placeholder__bust-volume")} required onChange={handlerInputChange}/>
            <input type="number" name='Waist' placeholder={t("placeholder__waist")} required onChange={handlerInputChange}/>
            <input type="number" name='Thigh' placeholder={t("placeholder__hip-volume")} required onChange={handlerInputChange}/>
            <input type="number" name='Neck' placeholder={t("placeholder__neck-circumference")} required onChange={handlerInputChange}/>
            <input type="number" name='Leg' placeholder={t("placeholder__leg-length")} required onChange={handlerInputChange}/>
            <input type="number" name='Sleeve' placeholder={t("placeholder__length-sleeve")} required onChange={handlerInputChange}/>
        </div>
        <hr />
    </>
  )
}

export default MeasurementsForm