import React from 'react'

import './AdvantagesItem.css'
const AdvantagesItem = ({ title, text }) => {
  return (
    <div className='advantages__item'>
        <div className="advantages__item__title title">
            <div className='title-dot' />
            {title}
        </div>
        <p>
            {text}
        </p>
    </div>
  )
}

export default AdvantagesItem