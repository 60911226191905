import React from "react";
import { NavLink } from "react-router-dom";
import i18next from "i18next";

import "./Footer.css";
import { images } from "../../constants";
import { navigationD } from "../../data";
import { catalogD } from "../../data";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="container">
        <div className="footer__content">
          <div className="footer__items">
            <div className="footer__item">
              <img src={images.logo} alt="" />
              <NavLink to="/privacy-policy" className="link__privacy-policy">
                {t("privacy-policy")}
              </NavLink>
            </div>
            <div className="footer__item-nav">
              
              <div className="footer__item nav">
                {navigationD.map((item, idx) => (
                  <NavLink key={idx} to={item.href}>
                    {t(`${item.name}`)}
                  </NavLink>
                ))}
              </div>

              <div className="footer__item nav">
                <NavLink to="/catalog" className="footer__title">
                  {t("catalog")}
                </NavLink>
                {catalogD.map((item) => (
                  <NavLink key={item.id} to={`catalog/${item.name}`}>
                    {i18next.language === "ru" ? item.title.ru : item.title.en}
                  </NavLink>
                ))}
              </div>

              <div className="footer__item nav">
                <NavLink to="/contacts" className="footer__title">
                  {t("contacts")}
                </NavLink>
                <a href="tel:+79816830576">
                  {t("telephone")}{t("number")}
                </a>
                <a href="/">E-mail: order@wudao-wushu.ru</a>
                <a href="/">{t("whatsapp")}{t("number")}</a>
              </div>
              
              <div className="footer__item nav">
                <NavLink to="/about" className="footer__title">
                  {t("about")}
                </NavLink>
                {
                  i18next.language === "ru" ?
                    (
                      <>
                        <div className="data">
                          <p className="sup">Название организации</p>
                          <p>Индивидуальный предприниматель Титунов Дмитрий Олегович</p>
                        </div>
                        <div className="data">
                          <p className="sup">Юридический адрес организации</p>
                          <p>193231, Россия, г. Санкт-Петербург, пр-кт. Российский, д. 8, а, кв. 371</p>
                        </div><div className="data">
                          <p className="sup">ИНН</p>
                          <p>434581223304</p>
                        </div><div className="data">
                          <p className="sup">ОГРН</p>
                          <p>324784700084780</p>
                        </div>
                      </>
                    ):
                    (
                      <>
                        <div className="data">
                          <p className="sup">Name of the organization</p>
                          <p>Beijing Wudao sports goods Co. Ltd</p>
                        </div>
                        <div className="data">
                          <p className="sup">Registered address</p>
                          <p>China PR, Beijing, Haidian district, Beiqinglu 164, yard # 28-38, office number 1708</p>
                        </div>
                        <div className="data">
                          <p className="sup">Unified social credit code</p>
                          <p>91110108MAС53DHQ6J</p>
                        </div>
                      </>
                    )
                }
              </div>

            </div>
          </div>
          <div className="footer__policy">
            <p>© 2023 - {new Date().getFullYear()}</p>
            <p>{t("footer__policy")}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
