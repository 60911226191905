import React from "react";
import i18next from "i18next";
import "./CatalogSideBar.css";
import { catalogD, productsD } from "../../data"
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CatalogSideBar = () => {
  const { t } = useTranslation()
  return (
    <div className="catalog-side-bar">
      <div className="title">{t("catalog")}</div>
      <div className="catalog-side-bar__navigation">
        {catalogD.map((catalog, idx) => (
          <NavLink key={idx} to={`/catalog/${catalog.name}`} state={catalog}>
            {
              i18next.language === "ru" ? catalog.title.ru : catalog.title.en
            }
            {
              productsD[catalog.name].map((subcatalog, idx) => {
                if (subcatalog.goods != null) {
                  return (
                    <NavLink key={idx} to={`/catalog/${catalog.name}/${subcatalog.name}`} state={subcatalog}>
                      {
                        i18next.language === "ru" ? subcatalog.title.ru : subcatalog.title.en
                      }
                      {
                        subcatalog.goods.map((item, idx) => (
                          <NavLink key={idx} to={`/products/${catalog.name}/${subcatalog.name}/${item.id}`} state={item}>
                            {i18next.language === "ru"
                              ? item.title.ru
                              : item.title.en}
                          </NavLink>
                        ))
                      }
                    </NavLink>
                  );
                } else {
                  return (
                    <NavLink key={idx} to={`/products/${catalog.name}/${subcatalog.id}`} state={subcatalog}>
                      {
                        i18next.language === "ru" ? subcatalog.title.ru : subcatalog.title.en
                      }
                    </NavLink>
                  );
                }
              })
            }
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default CatalogSideBar;
