import React from 'react'

import './AchievementItem.css'
function AchievementItem(props){
    return (
        <div className='achievement-item'>
            <div className="title-text">{props.title}</div>
            <p className="text">{props.text}</p>
        </div>
    )
}

export default AchievementItem