import { catalogImages } from '../constants'
const catalogData = [
  {
    id: 1,
    name: "suits",
    title: { ru: "Костюмы", en: "Suits" },
    text: {
      ru: [
        "Костюмы из искусственного и натурального шелка для ушу из Китая на заказ. Доставка по всему миру.",
      ],
      en: [
        "Customizable wushu synthetic and natural silk from China.",
      ],
    },
    image: catalogImages.suitsHeader,
  },
  {
    id: 2,
    name: "vipsuits",
    title: { ru: "VIP-Костюмы", en: "VIP-suits" },
    text: {
      ru: [
        "Костюмы для ушу индивидуального пошива из Китая на заказ. Доставка по всему миру.",
      ],
      en: ["Customizable wushu suits with fantastic embroidery made of best silk in China."],
    },
    image: catalogImages.vipSuitsHeader
  },
  {
    id: 3,
    name: "shoes",
    title: { ru: "Обувь", en: "Shoes" },
    text: {
      ru: ["Обувь для ушу из Китая на заказ. Доставка по всему миру."],
      en: ["Different brands of wushu shoes directly from factories. Find a pair that resonates with you."],
    },
    image: catalogImages.shoesHeader
  },
  {
    id: 4,
    name: "weapon",
    title: { ru: "Оружие", en: "Weapon" },
    text: {
      ru: ["Оружие для ушу из Китая на заказ. Доставка по всему миру."],
      en: ["Compulsory and traditional wushu weapons. Worldwide delivery."],
    },
    image: catalogImages.weaponHeader
  },
  {
    id: 5,
    name: "accessories",
    title: { ru: "Аксессуары", en: "Accessories" },
    text: {
      ru: [
        "Костюмы, обувь и оружие для ушу из Китая на заказ. Доставка по всему миру.",
      ],
      en: [
        "Different kinds of wushu accessories. Worldwide delivery.",
      ],
    },
    image: catalogImages.accessoriesHeader
  },
  {
    id: 6,
    name: "sanda",
    title: { ru: "Саньда", en: "Sanda" },
    text: {
      ru: [
        "Форма и экипировка для тренировок и соревнований по ушу-саньда. Доставка по всему миру.",
      ],
      en: [
        "Wushu-sanda both competition and training gear for your best perfomances.",
      ],
    },
    image: catalogImages.sandaHeader
  },
  {
    id: 7,
    name: "cloth",
    title: { ru: "Одежда", en: "Apparel" },
    text: { 
      ru: ["Одежда для тренировок и повседневного ношения. Доставка по всему миру."], 
      en: ["Clothes for training and everyday wear. Worldwide delivery."] 
    },
    image: catalogImages.clothHeader
  },
  {
    id: 8,
    name: "carpets-platforms",
    title: { ru: "Ковры и помосты", en: "Carpets and coverings" },
    text: {
      ru: ["Покрытие для соревнований по ушу-таолу и саньда. Доставка по всему миру."],
      en: ["Carpets for wushu taolu and sanda. Worldwide delivery"],
    },
    image: catalogImages.carpetsPlatformsHeader
  },
];

export default catalogData