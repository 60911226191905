import React from 'react'
import { Contacts } from '../containers'
import { Navigation } from '../components'
import { useTranslation } from 'react-i18next'

const ContactsPage = () => {
  const { t } = useTranslation()
  return (
    <div className='contacts'>
      <Navigation />
      <Contacts />
    </div>
  )
}

export default ContactsPage