import React from 'react'

import './SendingMailForm.css'
import { NavLink } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const DeliveryForm = ({ onChange }) => {
  const { t } = useTranslation()

  const handlerInputChange = (e) => {
    onChange(e)
  }

  return (
    <>
        <div className="title">
          {i18next.language === "ru" ? "Укажите адрес доставки" : "Enter your delivery address"}
        </div>
        <div className="form__inputs">
            <input type="text" name='Name' placeholder={t("placeholder__first-name__second-name")} required onChange={handlerInputChange}/>
            <input type="email" name='Mail' placeholder={t("placeholder__mail")} required onChange={handlerInputChange}/>
            <input type="number" name='Phone' placeholder={t("placeholder__phone-number")} required onChange={handlerInputChange}/>
            <input type="text" name='Address' placeholder={t("placeholder__mailing-address")} required onChange={handlerInputChange}/>
            <input type="text" name='Index' placeholder={t("placeholder__index")} required onChange={handlerInputChange}/>
        </div>
        <div className="form__send">
            <button 
              className='form__send__button btn'>{t("call-back__form-send__btn")}</button>
            <div className="text">{t("call-back__form-send__text")}<NavLink to="/privacy-policy">{t("call-back__privacy-policy")}</NavLink></div>
        </div>
    </>
  )
}

export default DeliveryForm