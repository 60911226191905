import React, { useState } from 'react'

import './TransactionItem.css'
const TransactionItem = ({title, text}) => {
  const [display, setDisplay] = useState(false)

  return (
    <div className='transaction-item'>
      <div className="transaction-item__title" onClick={() => setDisplay(!display)}>
        <div className={display ? 'btn__more show' : 'btn__more'} />
        {title}
      </div>
      <div className={display ? 'transaction-item__text' : 'transaction-item__text display-hidden'}>
        {text}
      </div>
    </div>
  )
}

export default TransactionItem