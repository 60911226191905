import './CarouselGoods.css'
import React from 'react'
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

const CarouselGoods = ({data}) => {
  const popularGoodsData = {
    ru: [
      data.suits[0],
      data.sanda[9],
      data.shoes[0],
      data.weapon[7],
      data.cloth[6]
    ],
    en: [
      data.shoes[4],
      data.vipsuits[34],
      data.weapon[3],
      data.weapon[5],
      data.weapon[25],
    ]
  }
  
  

  return (
    <div className='carousel-goods'>
        <Swiper
            slidesPerView={1}
            spaceBetween={20}
            centeredSlides={true}
            roundLengths={true}
            loop={true}
            loopAdditionalSlides={1}
            pagination={
              {
                clickable: true,
              }
            }
            navigation={true}
            modules={[Navigation, Pagination]}
            breakpoints={
              { 992:{ 
                  slidesPerView: 3,
                } 
              }
            }
        >
            {(i18next.language === "ru" ? popularGoodsData.ru : popularGoodsData.en).map((item, idx) => 
                <SwiperSlide key={idx}>
                  <NavLink to={`/catalog/suits/${idx + 1}`} state={item}>
                    <img src={item.image} alt="" />
                    <div className="title">{i18next.language === 'ru' ? item.title.ru : item.title.en}</div>
                  </NavLink>
                </SwiperSlide>
            )}
        </Swiper>
    </div>
  )
}

export default CarouselGoods