import suits_1 from "../assets/catalog/suits/1.jpg";
import suits_2 from "../assets/catalog/suits/2.jpg";
import suits_3 from "../assets/catalog/suits/3.jpg";
import suits_4 from "../assets/catalog/suits/4.jpg";
import suits_5 from "../assets/catalog/suits/5.jpg";
import suits_6 from "../assets/catalog/suits/6.jpg";
import suits_7 from "../assets/catalog/suits/7.jpg";
import suits_8 from "../assets/catalog/suits/8.jpg";
import suits_9 from "../assets/catalog/suits/9.jpg";
import suits_10 from "../assets/catalog/suits/10.jpg";
import suits_11 from "../assets/catalog/suits/11.jpg";
import suits_12 from "../assets/catalog/suits/12.jpg";
import suits_13 from "../assets/catalog/suits/13.jpg";
import suits_14 from "../assets/catalog/suits/14.jpg";
import suits_15 from "../assets/catalog/suits/15.jpg";
import suits_16 from "../assets/catalog/suits/16.jpg";
import suits_17 from "../assets/catalog/suits/17.jpg";
import suits_18 from "../assets/catalog/suits/18.jpg";
import suits_19 from "../assets/catalog/suits/19.jpg";
import suits_20 from "../assets/catalog/suits/20.jpg";
import suits_21 from "../assets/catalog/suits/21.jpg";
import suits_22 from "../assets/catalog/suits/22.jpg";
import suits_23 from "../assets/catalog/suits/23.jpg";
import suits_24 from "../assets/catalog/suits/24.jpg";
import suits_25 from "../assets/catalog/suits/25.jpg";
import suits_26 from "../assets/catalog/suits/26.jpg";
import suits_27 from "../assets/catalog/suits/27.jpg";
import suits_28 from "../assets/catalog/suits/28.jpg";
import suits_29 from "../assets/catalog/suits/29.jpg";
import suits_30 from "../assets/catalog/suits/30.jpg";
import suits_31 from "../assets/catalog/suits/31.jpg";
import suits_32 from "../assets/catalog/suits/32.jpg";
import suits_33 from "../assets/catalog/suits/33.jpg";
import suits_34 from "../assets/catalog/suits/34.jpg";
import suits_35 from "../assets/catalog/suits/35.jpg";
import suits_36 from "../assets/catalog/suits/36.jpg";
import suits_37 from "../assets/catalog/suits/37.jpg";
import suits_38 from "../assets/catalog/suits/38.jpg";
import suits_39 from "../assets/catalog/suits/39.jpg";
import suits_40 from "../assets/catalog/suits/40.jpg";
import suits_41 from "../assets/catalog/suits/41.jpg";
import suits_42 from "../assets/catalog/suits/42.jpg";
import suits_43 from "../assets/catalog/suits/43.jpg";
import suits_44 from "../assets/catalog/suits/44.jpg";
import suits_45 from "../assets/catalog/suits/45.jpg";
import suits_46 from "../assets/catalog/suits/46.jpg";
import suits_47 from "../assets/catalog/suits/47.jpg";
import suits_48 from "../assets/catalog/suits/48.jpg";
import suits_49 from "../assets/catalog/suits/49.jpg";
import suits_50 from "../assets/catalog/suits/50.jpg";
import suits_51 from "../assets/catalog/suits/51.jpg";
import suits_52 from "../assets/catalog/suits/52.jpg";
import suits_53 from "../assets/catalog/suits/53.jpg";
import suits_54 from "../assets/catalog/suits/54.jpg";
import suits_55 from "../assets/catalog/suits/55.jpg";
import suits_56 from "../assets/catalog/suits/56.jpg";
import suits_57 from "../assets/catalog/suits/57.jpg";
import suits_58 from "../assets/catalog/suits/58.jpg";
import suits_59 from "../assets/catalog/suits/59.jpg";
import suits_60 from "../assets/catalog/suits/60.jpg";
import suits_61 from "../assets/catalog/suits/61.jpg";
import suits_62 from "../assets/catalog/suits/62.jpg";
import suits_63 from "../assets/catalog/suits/63.jpg";
import suits_64 from "../assets/catalog/suits/64.jpg";
import suits_65 from "../assets/catalog/suits/65.jpg";
import suits_66 from "../assets/catalog/suits/66.jpg";
import suits_67 from "../assets/catalog/suits/67.jpg";
import suits_68 from "../assets/catalog/suits/68.jpg";
import suits_69 from "../assets/catalog/suits/69.jpg";
import suits_70 from "../assets/catalog/suits/70.jpg";
import suits_71 from "../assets/catalog/suits/71.jpg";
import suits_72 from "../assets/catalog/suits/72.jpg";
import suits_73 from "../assets/catalog/suits/73.jpg";
import suits_74 from "../assets/catalog/suits/74.jpg";
import suits_75 from "../assets/catalog/suits/75.jpg";
import suits_76 from "../assets/catalog/suits/76.jpg";
import suits_77 from "../assets/catalog/suits/77.jpg";
import suits_78 from "../assets/catalog/suits/78.jpg";
import suits_79 from "../assets/catalog/suits/79.jpg";
import suits_80 from "../assets/catalog/suits/80.jpg";
import suits_81 from "../assets/catalog/suits/81.jpg";
import suits_82 from "../assets/catalog/suits/82.jpg";
import suits_83 from "../assets/catalog/suits/83.jpg";
import suits_84 from "../assets/catalog/suits/84.jpg";
import suits_85 from "../assets/catalog/suits/85.jpg";
import suits_86 from "../assets/catalog/suits/86.jpg";
import suits_87 from "../assets/catalog/suits/87.jpg";
import suits_88 from "../assets/catalog/suits/88.jpg";
import suits_89 from "../assets/catalog/suits/89.jpg";
import suits_90 from "../assets/catalog/suits/90.jpg";
import suits_91 from "../assets/catalog/suits/91.jpg";
import suits_92 from "../assets/catalog/suits/92.jpg";

import vipSuits_1 from "../assets/catalog/vip-suits/1-min.jpg";
import vipSuits_2 from "../assets/catalog/vip-suits/2-min.jpg";
import vipSuits_3 from "../assets/catalog/vip-suits/3-min.jpg";
import vipSuits_4 from "../assets/catalog/vip-suits/4-min.jpg";
import vipSuits_5 from "../assets/catalog/vip-suits/5-min.jpg";
import vipSuits_6 from "../assets/catalog/vip-suits/6-min.jpg";
import vipSuits_7 from "../assets/catalog/vip-suits/7-min.jpg";
import vipSuits_8 from "../assets/catalog/vip-suits/8-min.jpg";
import vipSuits_9 from "../assets/catalog/vip-suits/9-min.jpg";
import vipSuits_10 from "../assets/catalog/vip-suits/10-min.jpg";
import vipSuits_11 from "../assets/catalog/vip-suits/11-min.jpg";
import vipSuits_12 from "../assets/catalog/vip-suits/12-min.jpg";
import vipSuits_13 from "../assets/catalog/vip-suits/13-min.jpg";
import vipSuits_14 from "../assets/catalog/vip-suits/14-min.jpg";
import vipSuits_15 from "../assets/catalog/vip-suits/15-min.jpg";
import vipSuits_16 from "../assets/catalog/vip-suits/16-min.jpg";
import vipSuits_17 from "../assets/catalog/vip-suits/17-min.jpg";
import vipSuits_18 from "../assets/catalog/vip-suits/18-min.jpg";
import vipSuits_19 from "../assets/catalog/vip-suits/19-min.jpg";
import vipSuits_20 from "../assets/catalog/vip-suits/20-min.jpg";
import vipSuits_21 from "../assets/catalog/vip-suits/21-min.jpg";
import vipSuits_22 from "../assets/catalog/vip-suits/22-min.jpg";
import vipSuits_23 from "../assets/catalog/vip-suits/23-min.jpg";
import vipSuits_24 from "../assets/catalog/vip-suits/24-min.jpg";
import vipSuits_25 from "../assets/catalog/vip-suits/25-min.jpg";
import vipSuits_26 from "../assets/catalog/vip-suits/26-min.jpg";
import vipSuits_27 from "../assets/catalog/vip-suits/27-min.jpg";
import vipSuits_28 from "../assets/catalog/vip-suits/28-min.jpg";
import vipSuits_29 from "../assets/catalog/vip-suits/29-min.jpg";
import vipSuits_30 from "../assets/catalog/vip-suits/30-min.jpg";
import vipSuits_31 from "../assets/catalog/vip-suits/31-min.jpg";
import vipSuits_32 from "../assets/catalog/vip-suits/32-min.jpg";
import vipSuits_33 from "../assets/catalog/vip-suits/33-min.jpg";
import vipSuits_34 from "../assets/catalog/vip-suits/34-min.jpg";
import vipSuits_35 from "../assets/catalog/vip-suits/35-min.jpg";
import vipSuits_36 from "../assets/catalog/vip-suits/36-min.jpg";
import vipSuits_38 from "../assets/catalog/vip-suits/38-min.jpg";
import vipSuits_39 from "../assets/catalog/vip-suits/39-min.jpg";
import vipSuits_40 from "../assets/catalog/vip-suits/40-min.jpg";
import vipSuits_41 from "../assets/catalog/vip-suits/41-min.jpg";
import vipSuits_42 from "../assets/catalog/vip-suits/42-min.jpg";
import vipSuits_43 from "../assets/catalog/vip-suits/43-min.jpg";
import vipSuits_44 from "../assets/catalog/vip-suits/44-min.jpg";
import vipSuits_45 from "../assets/catalog/vip-suits/45-min.jpg";
import vipSuits_46 from "../assets/catalog/vip-suits/46-min.jpg";
import vipSuits_47 from "../assets/catalog/vip-suits/47-min.jpg";
import vipSuits_48 from "../assets/catalog/vip-suits/48-min.jpg";
import vipSuits_49 from "../assets/catalog/vip-suits/49-min.jpg";
import vipSuits_50 from "../assets/catalog/vip-suits/50-min.jpg";
import vipSuits_51 from "../assets/catalog/vip-suits/51-min.jpg";
import vipSuits_52 from "../assets/catalog/vip-suits/52-min.jpg";
import vipSuits_53 from "../assets/catalog/vip-suits/53-min.jpg";
import vipSuits_54 from "../assets/catalog/vip-suits/54-min.jpg";
import vipSuits_55 from "../assets/catalog/vip-suits/55-min.jpg";
import vipSuits_56 from "../assets/catalog/vip-suits/56-min.jpg";
import vipSuits_57 from "../assets/catalog/vip-suits/57-min.jpg";
import vipSuits_58 from "../assets/catalog/vip-suits/58-min.jpg";
import vipSuits_59 from "../assets/catalog/vip-suits/59-min.jpg";
import vipSuits_60 from "../assets/catalog/vip-suits/60-min.jpg";
import vipSuits_61 from "../assets/catalog/vip-suits/61-min.jpg";
import vipSuits_62 from "../assets/catalog/vip-suits/62-min.jpg";
import vipSuits_63 from "../assets/catalog/vip-suits/63-min.jpg";
import vipSuits_64 from "../assets/catalog/vip-suits/64-min.jpg";
import vipSuits_65 from "../assets/catalog/vip-suits/65-min.jpg";
import vipSuits_66 from "../assets/catalog/vip-suits/66-min.jpg";
import vipSuits_67 from "../assets/catalog/vip-suits/67-min.jpg";
import vipSuits_68 from "../assets/catalog/vip-suits/68-min.jpg";
import vipSuits_69 from "../assets/catalog/vip-suits/69-min.jpg";
import vipSuits_70 from "../assets/catalog/vip-suits/70-min.jpg";
import vipSuits_71 from "../assets/catalog/vip-suits/71-min.jpg";
import vipSuits_72 from "../assets/catalog/vip-suits/72-min.jpg";
import vipSuits_73 from "../assets/catalog/vip-suits/73-min.jpg";
import vipSuits_74 from "../assets/catalog/vip-suits/74-min.jpg";
import vipSuits_75 from "../assets/catalog/vip-suits/75-min.jpg";
import vipSuits_76 from "../assets/catalog/vip-suits/76-min.jpg";
import vipSuits_77 from "../assets/catalog/vip-suits/77-min.jpg";
import vipSuits_78 from "../assets/catalog/vip-suits/78-min.jpg";
import vipSuits_79 from "../assets/catalog/vip-suits/79-min.jpg";
import vipSuits_80 from "../assets/catalog/vip-suits/80-min.jpg";
import vipSuits_81 from "../assets/catalog/vip-suits/81-min.jpg";
import vipSuits_82 from "../assets/catalog/vip-suits/82-min.jpg";
import vipSuits_83 from "../assets/catalog/vip-suits/83-min.jpg";
import vipSuits_84 from "../assets/catalog/vip-suits/84-min.jpg";
import vipSuits_85 from "../assets/catalog/vip-suits/85-min.jpg";
import vipSuits_86 from "../assets/catalog/vip-suits/86-min.jpg";
import vipSuits_87 from "../assets/catalog/vip-suits/87-min.jpg";
import vipSuits_88 from "../assets/catalog/vip-suits/88-min.jpg";
import vipSuits_89 from "../assets/catalog/vip-suits/89-min.jpg";
import vipSuits_90 from "../assets/catalog/vip-suits/90-min.jpg";
import vipSuits_91 from "../assets/catalog/vip-suits/91-min.jpg";
import vipSuits_92 from "../assets/catalog/vip-suits/92-min.jpg";
import vipSuits_93 from "../assets/catalog/vip-suits/93-min.jpg";
import vipSuits_94 from "../assets/catalog/vip-suits/94-min.jpg";
import vipSuits_95 from "../assets/catalog/vip-suits/95-min.jpg";
import vipSuits_96 from "../assets/catalog/vip-suits/96-min.jpg";
import vipSuits_97 from "../assets/catalog/vip-suits/97-min.jpg";
import vipSuits_98 from "../assets/catalog/vip-suits/98-min.jpg";
import vipSuits_99 from "../assets/catalog/vip-suits/99-min.jpg";
import vipSuits_100 from "../assets/catalog/vip-suits/100-min.jpg";
import vipSuits_101 from "../assets/catalog/vip-suits/101-min.jpg";
import vipSuits_102 from "../assets/catalog/vip-suits/102-min.jpg";
import vipSuits_103 from "../assets/catalog/vip-suits/103-min.jpg";
import vipSuits_104 from "../assets/catalog/vip-suits/104-min.jpg";
import vipSuits_105 from "../assets/catalog/vip-suits/105-min.jpg";
import vipSuits_106 from "../assets/catalog/vip-suits/106-min.jpg";
import vipSuits_107 from "../assets/catalog/vip-suits/107-min.jpg";
import vipSuits_108 from "../assets/catalog/vip-suits/108-min.jpg";
import vipSuits_109 from "../assets/catalog/vip-suits/109-min.jpg";
import vipSuits_110 from "../assets/catalog/vip-suits/110-min.jpg";
import vipSuits_111 from "../assets/catalog/vip-suits/111-min.jpg";
import vipSuits_112 from "../assets/catalog/vip-suits/112-min.jpg";
import vipSuits_113 from "../assets/catalog/vip-suits/113-min.jpg";
import vipSuits_114 from "../assets/catalog/vip-suits/114-min.jpg";
import vipSuits_115 from "../assets/catalog/vip-suits/115-min.jpg";
import vipSuits_116 from "../assets/catalog/vip-suits/116-min.jpg";

import feiyue from "../assets/catalog/shoes/feiyue.jpg";
import feiyue_2 from "../assets/catalog/shoes/feiyue/2.jpg";
import feiyue_1 from "../assets/catalog/shoes/feiyue/1.jpg";

import budosaga from "../assets/catalog/shoes/budosaga.jpg";
import budosaga_1 from "../assets/catalog/shoes/budosaga/1.jpg";
import budosaga_2 from "../assets/catalog/shoes/budosaga/2.jpg";
import budosaga_3 from "../assets/catalog/shoes/budosaga/3.jpg";
import budosaga_4 from "../assets/catalog/shoes/budosaga/4.jpg";
import budosaga_5 from "../assets/catalog/shoes/budosaga/5.jpg";
import budosaga_6 from "../assets/catalog/shoes/budosaga/6.jpg";

import loveWushu from "../assets/catalog/shoes/loveWushu.jpg";
import loveWushu_1 from "../assets/catalog/shoes/loveWushu/1.jpg";
import loveWushu_2 from "../assets/catalog/shoes/loveWushu/2.jpg";

import gwc from "../assets/catalog/shoes/gwc.jpg";
import gwc_1 from "../assets/catalog/shoes/gwc/1.jpg";
import gwc_2 from "../assets/catalog/shoes/gwc/2.jpg";

import wuYue from "../assets/catalog/shoes/wuYue.jpg";
import wuYue_1 from "../assets/catalog/shoes/wuYue/1.jpg";
import wuYue_2 from "../assets/catalog/shoes/wuYue/2.jpg";

import doWin from "../assets/catalog/shoes/doWin.jpg";
import doWin_1 from "../assets/catalog/shoes/doWin/1.jpg";
import doWin_2 from "../assets/catalog/shoes/doWin/2.jpg";

import daehentong from "../assets/catalog/shoes/daehentong.jpg";
import daehentong_1 from "../assets/catalog/shoes/daehentong/1.jpg";
import daehentong_2 from "../assets/catalog/shoes/daehentong/2.jpg";
import daehentong_3 from "../assets/catalog/shoes/daehentong/3.jpg";

import asics from "../assets/catalog/shoes/asics.jpg"
import asics_1 from "../assets/catalog/shoes/asics/1.jpg"
import asics_2 from "../assets/catalog/shoes/asics/2.jpg"
import asics_3 from "../assets/catalog/shoes/asics/3.jpg"
import asics_4 from "../assets/catalog/shoes/asics/4.jpg"

import weapons_1 from "../assets/catalog/weapons/1.jpg";
import weapons_2 from "../assets/catalog/weapons/2.jpg";
import weapons_3 from "../assets/catalog/weapons/3.jpg";
import weapons_4 from "../assets/catalog/weapons/4.jpg";
import weapons_5 from "../assets/catalog/weapons/5.jpg";
import weapons_6 from "../assets/catalog/weapons/6.jpg";
import weapons_7 from "../assets/catalog/weapons/7.jpg";
import weapons_8 from "../assets/catalog/weapons/8.jpg";
import weapons_9 from "../assets/catalog/weapons/9.jpg";
import weapons_10 from "../assets/catalog/weapons/10.jpg";
import weapons_11 from "../assets/catalog/weapons/11.jpg";
import weapons_12 from "../assets/catalog/weapons/12.jpg";
import weapons_13 from "../assets/catalog/weapons/13.jpg";
import weapons_14 from "../assets/catalog/weapons/14.jpg";
import weapons_15 from "../assets/catalog/weapons/15.jpg";
import weapons_16 from "../assets/catalog/weapons/16.jpg";
import weapons_17 from "../assets/catalog/weapons/17.jpg";
import weapons_18 from "../assets/catalog/weapons/18.jpg";
import weapons_19 from "../assets/catalog/weapons/19.jpg";
import weapons_20 from "../assets/catalog/weapons/20.jpg";
import weapons_21 from "../assets/catalog/weapons/21.jpg";
import weapons_22 from "../assets/catalog/weapons/22.jpg";
import weapons_23 from "../assets/catalog/weapons/23.jpg";
import weapons_24 from "../assets/catalog/weapons/24.jpg";
import weapons_25 from "../assets/catalog/weapons/25.jpg";
import weapons_26 from "../assets/catalog/weapons/26.jpg";
import weapons_27 from "../assets/catalog/weapons/27.jpg";

import accessories_1 from "../assets/catalog/accessories/1.jpg";
import accessories_2 from "../assets/catalog/accessories/2.jpg";
import accessories_3 from "../assets/catalog/accessories/3.jpg";
import accessories_4 from "../assets/catalog/accessories/4.jpg";
import accessories_5 from "../assets/catalog/accessories/5.jpg";
import accessories_6 from "../assets/catalog/accessories/6.jpg";
import accessories_7 from "../assets/catalog/accessories/7.jpg";
import accessories_8 from "../assets/catalog/accessories/8.jpg";
import accessories_9 from "../assets/catalog/accessories/9.jpg";
import accessories_10 from "../assets/catalog/accessories/10.jpg";
import accessories_11 from "../assets/catalog/accessories/11.jpg";

import sanda_1 from "../assets/catalog/sanda/1.jpg";
import sanda_2 from "../assets/catalog/sanda/2.jpg";
import sanda_3 from "../assets/catalog/sanda/3.jpg";
import sanda_4 from "../assets/catalog/sanda/4.jpg";
import sanda_5 from "../assets/catalog/sanda/5.jpg";
import sanda_6 from "../assets/catalog/sanda/6.jpg";
import sanda_7 from "../assets/catalog/sanda/7.jpg";
import sanda_8 from "../assets/catalog/sanda/8.jpg";
import sanda_9 from "../assets/catalog/sanda/9.jpg";
import sanda_10 from "../assets/catalog/sanda/10.jpg";
import sanda_11 from "../assets/catalog/sanda/11.jpg";
import sanda_12 from "../assets/catalog/sanda/12.jpg";
import sanda_13 from "../assets/catalog/sanda/13.jpg";
import sanda_14 from "../assets/catalog/sanda/14.jpg";
import sanda_15 from "../assets/catalog/sanda/15.jpg";
import sanda_16 from "../assets/catalog/sanda/16.jpg";

import cloth_1 from "../assets/catalog/cloth/1.jpg"
import cloth_2 from "../assets/catalog/cloth/2.jpg"
import cloth_3 from "../assets/catalog/cloth/3.jpg"
import cloth_4 from "../assets/catalog/cloth/4.jpg"
import cloth_5 from "../assets/catalog/cloth/5.jpg"
import cloth_6 from "../assets/catalog/cloth/6.jpg"
import cloth_7 from "../assets/catalog/cloth/7.jpg"
import cloth_8 from "../assets/catalog/cloth/8.jpg"
import cloth_9 from "../assets/catalog/cloth/9.jpg"
import cloth_10 from "../assets/catalog/cloth/10.jpg"
import cloth_11 from "../assets/catalog/cloth/11.jpg"
import cloth_12 from "../assets/catalog/cloth/12.jpg"
import cloth_13 from "../assets/catalog/cloth/13.jpg"
import cloth_14 from "../assets/catalog/cloth/14.jpg"
import cloth_15 from "../assets/catalog/cloth/15.jpg"
import cloth_16 from "../assets/catalog/cloth/16.jpg"

import carpetPlatforms_1 from "../assets/catalog/carpet-platforms/1.jpg"
import carpetPlatforms_2 from "../assets/catalog/carpet-platforms/2.JPG"


export default{
  suits_1,
  suits_2,
  suits_3,
  suits_4,
  suits_5,
  suits_6,
  suits_7,
  suits_8,
  suits_9,
  suits_10,
  suits_11,
  suits_12,
  suits_13,
  suits_14,
  suits_15,
  suits_16,
  suits_17,
  suits_18,
  suits_19,
  suits_20,
  suits_21,
  suits_22,
  suits_23,
  suits_24,
  suits_25,
  suits_26,
  suits_27,
  suits_28,
  suits_29,
  suits_30,
  suits_31,
  suits_32,
  suits_33,
  suits_34,
  suits_35,
  suits_36,
  suits_37,
  suits_38,
  suits_39,
  suits_40,
  suits_41,
  suits_42,
  suits_43,
  suits_44,
  suits_45,
  suits_46,
  suits_47,
  suits_48,
  suits_49,
  suits_50,
  suits_51,
  suits_52,
  suits_53,
  suits_54,
  suits_55,
  suits_56,
  suits_57,
  suits_58,
  suits_59,
  suits_60,
  suits_61,
  suits_62,
  suits_63,
  suits_64,
  suits_65,
  suits_66,
  suits_67,
  suits_68,
  suits_69,
  suits_70,
  suits_71,
  suits_72,
  suits_73,
  suits_74,
  suits_75,
  suits_76,
  suits_77,
  suits_78,
  suits_79,
  suits_80,
  suits_81,
  suits_82,
  suits_83,
  suits_84,
  suits_85,
  suits_86,
  suits_87,
  suits_88,
  suits_89,
  suits_90,
  suits_91,
  suits_92,

  vipSuits_1,
  vipSuits_2,
  vipSuits_3,
  vipSuits_4,
  vipSuits_5,
  vipSuits_6,
  vipSuits_7,
  vipSuits_8,
  vipSuits_9,
  vipSuits_10,
  vipSuits_11,
  vipSuits_12,
  vipSuits_13,
  vipSuits_14,
  vipSuits_15,
  vipSuits_16,
  vipSuits_17,
  vipSuits_18,
  vipSuits_19,
  vipSuits_20,
  vipSuits_21,
  vipSuits_22,
  vipSuits_23,
  vipSuits_24,
  vipSuits_25,
  vipSuits_26,
  vipSuits_27,
  vipSuits_28,
  vipSuits_29,
  vipSuits_30,
  vipSuits_31,
  vipSuits_32,
  vipSuits_33,
  vipSuits_34,
  vipSuits_35,
  vipSuits_36,
  vipSuits_38,
  vipSuits_39,
  vipSuits_40,
  vipSuits_41,
  vipSuits_42,
  vipSuits_43,
  vipSuits_44,
  vipSuits_45,
  vipSuits_46,
  vipSuits_47,
  vipSuits_48,
  vipSuits_49,
  vipSuits_50,
  vipSuits_51,
  vipSuits_52,
  vipSuits_53,
  vipSuits_54,
  vipSuits_55,
  vipSuits_56,
  vipSuits_57,
  vipSuits_58,
  vipSuits_59,
  vipSuits_60,
  vipSuits_61,
  vipSuits_62,
  vipSuits_63,
  vipSuits_64,
  vipSuits_65,
  vipSuits_66,
  vipSuits_67,
  vipSuits_68,
  vipSuits_69,
  vipSuits_70,
  vipSuits_71,
  vipSuits_72,
  vipSuits_73,
  vipSuits_74,
  vipSuits_75,
  vipSuits_76,
  vipSuits_77,
  vipSuits_78,
  vipSuits_79,
  vipSuits_80,
  vipSuits_81,
  vipSuits_82,
  vipSuits_83,
  vipSuits_84,
  vipSuits_85,
  vipSuits_86,
  vipSuits_87,
  vipSuits_88,
  vipSuits_89,
  vipSuits_90,
  vipSuits_91,
  vipSuits_92,
  vipSuits_93,
  vipSuits_94,
  vipSuits_95,
  vipSuits_96,
  vipSuits_97,
  vipSuits_98,
  vipSuits_99,
  vipSuits_100,
  vipSuits_101,
  vipSuits_102,
  vipSuits_103,
  vipSuits_104,
  vipSuits_105,
  vipSuits_106,
  vipSuits_107,
  vipSuits_108,
  vipSuits_109,
  vipSuits_110,
  vipSuits_111,
  vipSuits_112,
  vipSuits_113,
  vipSuits_114,
  vipSuits_115,
  vipSuits_116,

  feiyue,
  feiyue_2,
  feiyue_1,

  budosaga,
  budosaga_1,
  budosaga_2,
  budosaga_3,
  budosaga_4,
  budosaga_5,
  budosaga_6,

  loveWushu,
  loveWushu_1,
  loveWushu_2,

  gwc,
  gwc_1,
  gwc_2,

  wuYue,
  wuYue_1,
  wuYue_2,

  doWin,
  doWin_1,
  doWin_2,

  daehentong,
  daehentong_1,
  daehentong_2,
  daehentong_3,

  asics,
  asics_1,
  asics_2,
  asics_3,
  asics_4,

  weapons_1,
  weapons_2,
  weapons_3,
  weapons_4,
  weapons_5,
  weapons_6,
  weapons_7,
  weapons_8,
  weapons_9,
  weapons_10,
  weapons_11,
  weapons_12,
  weapons_13,
  weapons_14,
  weapons_15,
  weapons_16,
  weapons_17,
  weapons_18,
  weapons_19,
  weapons_20,
  weapons_21,
  weapons_22,
  weapons_23,
  weapons_24,
  weapons_25,
  weapons_26,
  weapons_27,

  accessories_1,
  accessories_2,
  accessories_3,
  accessories_4,
  accessories_5,
  accessories_6,
  accessories_7,
  accessories_8,
  accessories_9,
  accessories_10,
  accessories_11,

  sanda_1,
  sanda_2,
  sanda_3,
  sanda_4,
  sanda_5,
  sanda_6,
  sanda_7,
  sanda_8,
  sanda_9,
  sanda_10,
  sanda_11,
  sanda_12,
  sanda_13,
  sanda_14,
  sanda_15,
  sanda_16,

  cloth_1,
  cloth_2,
  cloth_3,
  cloth_4,
  cloth_5,
  cloth_6,
  cloth_7,
  cloth_8,
  cloth_9,
  cloth_10,
  cloth_11,
  cloth_12,
  cloth_13,
  cloth_14,
  cloth_15,
  cloth_16,

  carpetPlatforms_1,
  carpetPlatforms_2,
};
