const defaultState = {
    stateModal: ""
}

const ENABLE_MODAL = "ENABLE_MODAL"
const DISABLE_MODAL = "DISABLE_MODAL"

export default function completeModalReduser(state = defaultState, action){
    switch(action.type){
        case ENABLE_MODAL:
            return {...state, stateModal: "active" }
        case DISABLE_MODAL:
            return {...state, stateModal: "" }
        default:
            return state
    }
}

export const enableModalAction = () => ({type: ENABLE_MODAL})
export const disableModalAction = () => ({type: DISABLE_MODAL})