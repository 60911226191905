import React from 'react'
import './CompleteModal.css'
import "../Modals.css"
import { useDispatch, useSelector } from 'react-redux'
import { disableModalAction } from '../../../store/redusers/completeModalReduser'
import { images } from '../../../constants'

import { useTranslation } from "react-i18next";

const CompleteModal = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const stateModal = useSelector(state => state.completeModalReduser.stateModal)
    return(
        <div className={"modal " + stateModal}>
            <div className='modal__container'>
                <button className='modal__btn-close' onClick={() => dispatch(disableModalAction())}><img alt='' src={images.black_close} /></button>
                <div className='modal__message'>
                    <img src={images.success} alt="success" />
                    <button onClick={() => dispatch(disableModalAction())}>OK</button>
                </div>
            </div>
        </div>
    )
}

export default CompleteModal