import React from 'react'

import './PopularGoods.css'
import { CarouselGoods } from '../../components'
import { productsD } from '../../data'
import { useTranslation } from 'react-i18next'

const PopularGoods = () => {
  const { t } = useTranslation()
  return (
    <div className='popular-goods'>
        <div className="container">
            <div className="popular-goods__content">
                <div className="title">{t('popular-goods__title')}</div>
                <CarouselGoods data={productsD} />
            </div>
        </div>
    </div>
  )
}

export default PopularGoods