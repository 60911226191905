const defaultState = {
    goods: []
}

const ADD_PRODUCT = "ADD_PRODUCT"
const REMOVE_PRODUCT = "REMOVE_PRODUCT"
const REMOVE_PRODUCTS = "REMOVE_PRODUCTS"
const SET_COUNT_PRODUCT = "SET_COUNT_PRODUCT"

export default function basketReduser(state = defaultState, action){
    switch(action.type){
        case ADD_PRODUCT:
            return {...state, goods: [...state.goods, action.payload] }
        case REMOVE_PRODUCT:
            return {...state, goods: state.goods.filter(goods => goods.id !== action.payload)}
        case REMOVE_PRODUCTS:
            return defaultState
        case SET_COUNT_PRODUCT:
            return state
            // return {...state, goods: state.goods
            //     .find(element => element.id === action.payload.id).count + action.payload.value}
        default:
            return state
    }
}

export const addProductAction = (payload) => ({type: ADD_PRODUCT, payload})
export const removeProductAction = (id) => ({type: REMOVE_PRODUCT, payload: id})
export const removeProductsAction = () => ({type: REMOVE_PRODUCTS})
export const setCountProductAction = (id, value) => ({type: SET_COUNT_PRODUCT, payload: { id, value }})