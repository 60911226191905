import React from 'react'

import './SendingDSECForm.css'
import { NavLink } from 'react-router-dom'
const DeliveryForm = ({ onChange }) => {
  const handlerInputChange = (e) => {
    onChange(e)
  }

  return (
    <>
        <div className="title">
          Укажите адрес пункта СДЭК
        </div>
        <div className="form__inputs">
            <input type="text" name='Name' placeholder='Ф.И.О' required onChange={handlerInputChange}/>
            <input type="email" name='Mail' placeholder='Эл. почта' required onChange={handlerInputChange}/>
            <input type="number" name='Phone' placeholder='Номер телефона' required onChange={handlerInputChange}/>
            <input type="text" name='Address' placeholder='Город' required onChange={handlerInputChange}/>
            <input type="text" name='Index' placeholder='Адрес пункта выдачи СДЭК' required onChange={handlerInputChange}/>
        </div>
        <div className="form__send">
            <button 
              //disabled
              className='form__send__button btn'>Отправить</button>
            <div className="text">Нажимая отправить вы соглашаетесь с <NavLink to="/privacy-policy">политикой конфиденциальности</NavLink></div>
        </div>
    </>
  )
}

export default DeliveryForm