import React from 'react'
import { useTable } from 'react-table'

import './Table.css'
import i18next from 'i18next'
const Table = ({dHeader, dBody}) => {
    const data = React.useMemo(() => dBody, [])
    const columns = React.useMemo(() => dHeader, [])

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({columns, data})

    return (
        <table {...getTableProps()}>
            <thead>
                {
                    headerGroups.map((headerGroup) => 
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {i18next.language === "ru" ? column.Header.ru : column.Header.en}
                                </th>
                            ))}
                        </tr>
                    )
                }
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return(
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => 
                                <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                </td>
                            )}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default Table