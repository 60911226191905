import "./Goods.css";
import { CatalogItem, CatalogSideBar, GoodsItem } from "../../components";
import goodsData from "../../data/productsData"
import { useParams } from "react-router-dom";

const Goods = () => {
  const params = useParams()
  let goods = goodsData[params.catalog]
  if(params.subcatalog && params.catalog === 'shoes'){
    goods = goodsData.shoes.find(x => x.name === params.subcatalog).goods
  }
  return (
    <div className="goods">
      <div className="container">
        <div className="goods__content">
          <CatalogSideBar />
          <div className="catalog__view">
            {goods.map((item, idx) => {
                if (item.goods) {
                  return (
                    <CatalogItem
                      key={idx}
                      title={item.title}
                      text={item.text}
                      name={item.name}
                      goods={item.goods}
                      image={item.image}
                    />
                  );
                } else {
                  return (
                    <GoodsItem
                      key={idx}
                      params={params}
                      props={item}
                    />
                  );
                }
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Goods;
