import React from 'react'
import i18next from 'i18next'
import './Comments.css'
import { Comment } from '../../components'
import commentsData from '../../data/Comments.json'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode } from 'swiper/modules';
const Comments = () => {
  const { t } = useTranslation()
  return (
    <div className='comments'>
        <div className="container comments_container">
            <div className="comments__content">
              <div className="title">{t('comments__title')}</div>
              <div className="comments__items">
                <Swiper
                  slidesPerView={2}
                  centeredSlides={true}
                  setWrapperSize={true}
                  freeMode={true}
                  loop={true}
                  speed={25000}
                  autoplay={{
                    delay: 1,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, FreeMode]}
                >
                  {commentsData.map((item) => 
                    <SwiperSlide key={item.id}>
                      <Comment 
                        text={i18next.language === 'ru' ? item.text.ru : item.text.en} 
                        name={i18next.language === 'ru' ? item.name.ru : item.name.en} 
                        secondText={i18next.language === 'ru' ? item.secondText.ru : item.secondText.en}
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Comments