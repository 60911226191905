import React from 'react'

import './Offers.css'
import { Carousel } from '../../components'

import { images } from '../../constants'

const Offers = () => {
    const imageData = 
    [
        {
            src: 
            {
                ru: images.offer_1ru,
                en: images.offer_1en
            },
            alt: "offers_1"
        },
        {
            src: 
            {
                ru: images.offer_2ru,
                en: images.offer_2en
            },
            alt: "offers_2"
        },
        {
            src: 
            {
                ru: images.offer_3ru,
                en: images.offer_3en
            },
            alt: "offers_3"
        },
        {
            src: 
            {
                ru: images.offer_4ru,
                en: images.offer_4en
            },
            alt: "offers_4"
        }
    ]

    return (
        <div className='app__offers'>
            <div className="container">
                <div className="app__offers-content">                
                    <Carousel data={imageData}/>
                </div>
            </div>
        </div>
    )
}

export default Offers