import React from "react";

import "./FAQ.css";
import FAQItem from "../../components/FAQItem/FAQItem";
import i18next from "i18next";

const FAQ = () => {
  const faqData = { 
    ru: [
      {
        title: "Как вы работаете?",
        text: "Товары из наличия на складе в СПб отправляются дважды в неделю (среда и суббота) компанией СДЭК по льготным тарифам. Товары из Китая доставляются в Россию почтой (костюмы, обувь), а также автодорожным и железнодорожным транспортом (оружие, оптовые заказы).",
      },
      {
        title: "Сколько мне ждать мой заказ?",
        text: "Время доставки товаров из наличия составляет около недели. Доставка товаров из Китая занимает от 3-х недель до 1.5 месяцев.",
      },
      {
        title: "Включена ли доставка в стоимость заказа?",
        text: "У товаров из наличия доставка в стоимость не включена и оплачивается отдельно при получении в офисе СДЭК. У товаров из Китая, доставка которых до покупателя осуществляется почтой, пересылка в стоимость включена (костюмы, обувь, саньда) . В случае заказа оружия из Китая, доставка оплачивается покупателем отдельно в офисе СДЭК при получении по внутрироссийским льготным тарифам.",
      },
      {
        title: "Можно ли произвести обмен/возврат товара?",
        text: "Да, но если вы получили ваш заказ в соответствии с требованиями и он по каким-то причинам не подошел, то транспортные услуги по возврату его на наш склад ложатся на ваши плечи. Если неверный размер прислали мы, то возврат и новая доставка до вашего адреса производятся за наш счет. В случае обнаружения брака, доставка до склада и повторная отправка нового товара также происходят за наш счет.",
      },
      {
        title: "Как происходит оплата?",
        text: "Оплата за товары из наличия производится в полном объеме перед отправкой. Для товаров из Китая допускается авансовый платеж в размере 50% при заказе. Остальные 50% вносятся по факту отгрузки. Кроме того, по желанию клиента для товаров из Китая можно внести оплату и в полном объеме.",
      },
    ], 
    en: [
      {
        title: "What is the principle of your work?",
        text: "We operate worldwide, sending goods for Wushu (sport, traditional, Sanda) directly from Chinese factories. Upon receiving your order on our website, we will contact you via email or messenger to discuss the details. Orders are shipped after payment via international mail or courier services such as DHL, UPS, FedEx.",
      },
      {
        title: "How long do I have to wait for my order?",
        text: "Delivery of goods takes approximately 3 weeks with international mail and 1-2 weeks with DHL, UPS, FedEx.",
      },
      {
        title: "Is delivery included in the cost of the order?",
        text: "For some items, delivery is included in the cost, but it may vary depending on the delivery region. The standard shipping fee is 15 USD per item (costume, shoes, Sanda). In other cases, please check with the manager for clarification.",
      },
      {
        title: "Is it possible to exchange/return the goods?",
        text: "Yes, but if you received your order as requested and it doesn’t fit for some reason, the transportation costs for returning it to our warehouse are your responsibility. If an incorrect size was sent by us, the return and the new delivery to your address are covered by us.",
      },
      {
        title: "How is the payment processed?",
        text: "Payment for orders is made in full before shipping.",
      },
    ]
  };

  return (
    <div className="faq">
      <div className="container">
        <div className="faq__content">
          <div className="faq__items">
            {
              i18next.language === "ru" ?
                faqData.ru.map((item) => (<FAQItem title={item.title} text={item.text} />)) :
                faqData.en.map((item) => (<FAQItem title={item.title} text={item.text} />))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
