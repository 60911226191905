import React from 'react'
import { useParams } from 'react-router-dom'

import { Product, SecondaryHeader } from '../containers'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import goodsData from "../data/productsData"

const ProductPage = () => {
    const { t } = useTranslation()
    const params = useParams()
    
    let props
    if(params.catalog === 'shoes'){
        props = goodsData[params.catalog].find(x => x.name === params.subcatalog).goods[params.id - 1]
    }else{
        props = goodsData[params.catalog][params.id - 1]
    }
    return (
        <>
            <SecondaryHeader route={`/ ${t("catalog")} `} title={i18next.language === "ru" ? props.title.ru : props.title.en}/>
            <Product {...props} />
        </>
    )
}

export default ProductPage