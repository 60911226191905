import React from 'react'
import { NavLink } from 'react-router-dom'

import './Header.css'
import { images } from '../../constants'
import { useTranslation } from 'react-i18next'
import { Navigation } from '../../components'

function Header(){
  const { t } = useTranslation()
  return (
    <div className='header'>
      <Navigation />
      <div className="content">
        <div className="container">
          <div className="content__wrapper">
            <h1 className='content__title'>{t('header__title')}</h1>
            <h2 className="content__subtitle">{t('header__second-title')}</h2>
            <div className="content__advantages">
              <div className="icon"><img src={images.circleChech} alt="circle check" /><p>{t('plus-content-1')}</p></div>
              <div className="icon"><img src={images.circleChech} alt="circle check" /><p>{t('plus-content-2')}</p></div>
            </div>
            <div className="content__buttons">
              <NavLink to={"/catalog/suits"} className="primary-btn">{t('more-info-1')}</NavLink>
              <NavLink to={"/catalog"}>{t('more-info-2')}</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header