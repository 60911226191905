import React from 'react'
import i18next from 'i18next'

import './GoodsItem.css'
import { NavLink } from 'react-router-dom'
const GoodsItem = ({params, props}) => {
  return (
    <NavLink to={
        params.subcatalog 
          ? `/products/${params.catalog}/${params.subcatalog}/${props.id}` 
          : `/products/${params.catalog}/${props.id}`
          } 
        state={props} 
        className='goods-item'>
      <img src={props.image} alt="product" />
      <div className="title">{i18next.language === 'ru' ? props.title.ru : props.title.en}</div>
      <div className="text__container">
          {i18next.language === 'ru' ? props.text.ru.map((item, idx) => <div className='text' key={idx}>{item}</div>) : props.text.en.map((item, idx) => <div className='text' key={idx}>{item}</div>)}
      </div>
      <div className="price">{i18next.language === 'ru' ? props.price.ru + " руб.": props.price.en + " $"}</div>
    </NavLink>
  )
}

export default GoodsItem