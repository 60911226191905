import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  Home,
  AboutPage,
  CatalogPage,
  ContactsPage,
  FAQPage,
  GoodsPage,
  PrivacyPolicyPage,
  ProductPage,
} from "./pages";

import { Footer } from "./components";

import "./App.css";
import "./fonts/fonts.css"
import ScrollToTop from "./utlis/scrollToTop";
import { CompleteModal } from "./components/Modals";

const App = () => {
  return (
    <div className="app">
        <ScrollToTop />
        

        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="catalog" element={<CatalogPage />} />
          <Route path="catalog/:catalog" element={<GoodsPage />} />
          <Route path="catalog/:catalog/:subcatalog" element={<GoodsPage />} />
          <Route path="products/:catalog/:id" element={<ProductPage />} />
          <Route path="products/:catalog/:subcatalog/:id" element={<ProductPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contacts" element={<ContactsPage />} />
          <Route path="faq" element={<FAQPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <CompleteModal />
        <Footer />
    </div>
  );
};

export default App;
