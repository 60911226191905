import './CarouselCatalog.css'
import React from 'react'
import { NavLink } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

import i18next from 'i18next';

const CarouselCatalog = ({data}) => {
  return (
    <div className='carousel-catalog'>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          centeredSlides={true}
          roundLengths={true}
          loop={true}
          loopAdditionalSlides={1}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigation, Pagination]}

          breakpoints={
            { 
              992:{
                slidesPerView: 3,
              },
              1400:{ 
                slidesPerView: 5,
              } 
            }
          }
        >
            {data.map((item, idx) =>
              <SwiperSlide key={idx}>
                <NavLink to={`/catalog/${item.name}`} className="slide">
                  <img src={item.image} alt="" />
                  <div className="title">{i18next.language === 'ru' ? item.title.ru : item.title.en}</div>
                  <p className="text">{i18next.language === 'ru' ? item.text.ru : item.text.en}</p>
                </NavLink>
              </SwiperSlide>
            )}
        </Swiper>
    </div>
  )
}

export default CarouselCatalog