import React from 'react'

import './About.css'
import images from '../../constants/images'
import i18next from 'i18next'
const About = () => {
  const aboutBlog = {
    title: {
      ru:"Название нашей компании «Удао»",
      en:'The name of our company is "Wudao"'
    },
    text:{
      ru:[
        "состоящее из двух иероглифов 武 - боевой, военный и 道 - путь, можно перевести как «Путь воина». Как вы знаете, для того, чтобы быть воином и следовать этому пути, помимо технических навыков и физической подготовки, необходима качественная амуниция. Идея создания компании «Удао» пришла к нам в 2012 году, именно тогда два человека, посвятившие ушу всю свою жизнь и проживающие в двух концах света - Пекине и Санкт-Петербурге, решили снабжать ушуистов со всего мира, лучшей экипировкой, какую только можно найти в Китае - стране-родоначальнике ушу.",
        "Все из того, что представлено в ассортименте нашего магазина, было многократно опробовано нами и сотнями других спортсменов из разных уголков мира и до сих пор применяется в тренировочной и соревновательной деятельности. Наш лозунг «Мы экипируем чемпионов» появился не на пустом месте. Нас выбирают заслуженные мастера спорта и мастера спорта международного класса, чемпионы и призеры чемпионатов Мира, Европы и Азии. Кроме того, мы активно работаем с ДЮСШОР, спортивными секциями и клубами единоборств. А наши товары регулярно отправляются в США, Австралию, Канаду, Россию, Украину, Беларусь, Италию, Сингапур, Малайзию, Германию, Францию, Испанию, Португалию, Польшу, Нидерланды и многие другие страны.",
        "Мы лично осматриваем каждую партию оружия перед его отправкой с завода, принимаем изготовленные у китайских портных костюмы непосредственно из рук в руки, фотографируем каждую пару обуви, упаковываем ваши заказы и отправляем вам наиболее быстрым из возможных способов. Мы, как и любой воин, стремимся отточить наши навыки до совершенства, чтобы предоставить вам исключительный клиентский опыт. Удао - это наше детище, которое за время кропотливой работы выросло в респектабельную компанию с глобальной узнаваемостью, которая дорожит своей репутацией."
      ],
      en:[
        "Consisting of two characters, 武 - martial, military, and 道 - path, it can be translated as 'The Warrior's Path.' As you know, to be a warrior and follow this path, in addition to technical skills and physical training, high-quality equipment is necessary. The idea of creating the 'Wudao' company came to us in 2012. At that time, two individuals who dedicated their entire lives to Wushu and lived at opposite ends of the world - Beijing and St. Petersburg - decided to supply Wushu practitioners from all over the world with the best equipment available in China, the birthplace of Wushu.",
        "All the items presented in our store's assortment have been thoroughly tested by us and hundreds of other athletes from different parts of the world, and are still being used in training and competitive activities. Our slogan 'Equipping Champions' did not appear out of nowhere. It is chosen by deserved sports masters and masters of international class, champions, and medalists of World, European, and Asian Championships. In addition, we actively collaborate with sports schools, sections, and martial arts clubs. Our products are regularly shipped to the USA, Australia, Canada, Russia, Ukraine, Belarus, Italy, Singapore, Malaysia, Germany, France, Spain, Portugal, Poland, the Netherlands, and many other countries.",
        "We personally inspect every batch of weapons before sending it from the factory, receive tailor-made suits directly from Chinese tailors, photograph each pair of shoes, pack your orders, and ship them to you by the fastest possible means. Like any warrior, we strive to hone our skills to perfection to provide you with an exceptional customer experience. Udao is our brainchild, which, through meticulous work, has grown into a reputable company with global recognition that values its reputation."
      ]
    }
  }
  return (
    <div className='about'>
        <div className="container">
            <div className="about__content">
                <div className="title">{i18next.language === "ru" ? aboutBlog.title.ru : aboutBlog.title.en}</div>
                <div className="text">
                  <img src={images.about__img_1} alt="about-img" className='right' />
                  {i18next.language === "ru" ? aboutBlog.text.ru.map(item => <p>{item}</p>) : aboutBlog.text.en.map(item => <p>{item}</p>)}
                  <img src={images.about__img_2} alt="about-img" className='center' />
                </div>
            </div>
        </div>
    </div>
  )
}

export default About