import React from 'react'

import './Plus.css'
import { PlusItem } from '../../components'
import { images } from '../../constants'
import { useTranslation } from 'react-i18next'
const Plus = () => {
  const { t } = useTranslation()
  const plusData = [
    {
      id: 1,
      image: images.plus_1,
      title: "plus-1__title",
      text: "plus-1__text"
    },
    {
      id: 2,
      image: images.plus_2,
      title: "plus-2__title",
      text: "plus-2__text"
    },
    {
      id: 3,
      image: images.plus_3,
      title: "plus-3__title",
      text: "plus-3__text"
    },
    {
      id: 4,
      image: images.plus_4,
      title: "plus-4__title",
      text: "plus-4__text"
    }
  ]
  return (
    <div className='plus'>
        <div className="container">
            <div className='plus-items'>
                {plusData.map((item) => <PlusItem key={item.id} image={item.image} title={t(`${item.title}`)} text={t(`${item.text}`)} />)}
            </div>
        </div>
    </div>
  )
}

export default Plus