import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'

import './CallBack.css'
import { sendMessage } from '../../utlis/sendMessage'
import { enableModalAction } from '../../store/redusers/completeModalReduser';

const CallBack = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [inputData, setInputData] = useState('')

  let isLoad = false;

  const handlerInputChange = (e) => {
    setInputData({...inputData, [e.target.name]: e.target.value})
  }

  const getInputData = async (e) => {
    e.preventDefault()
    if(isLoad === true) return;
    isLoad = true

    try{
      sendMessage(`Сообщение обратной связи: %0A${JSON.stringify(inputData, null, '%0A').replace(/[{}"\s]/g, ' ')}`)
    }catch(ex){
      alert(ex)
    }
    dispatch(enableModalAction())
    isLoad = false
  }

  return (
    <div className='call-back'>
      <div className="container">
        <form onSubmit={getInputData} >
          <div className="title">{t('call-back__title')}</div>
          <div className="second-title">{t('call-back__second-title')}</div>
          <div className="form__inputs">
            <div className="form__inputs-left">
              <input type="text" name="name" placeholder={t('placeholder__name')} required onChange={handlerInputChange}/>
              <input type="email" name="email" placeholder={t('placeholder__email')} required onChange={handlerInputChange}/>
            </div>
            <textarea name="text" id="" cols="30" rows="10" placeholder={t('placeholder__message-text')} onChange={handlerInputChange}></textarea>
          </div>
          <div className="form__send">
            <div className="text">{t('call-back__form-send__text')} <NavLink to="/privacy-policy/">{t('call-back__privacy-policy')}</NavLink></div>
            <button className='form__send__button primary-btn'>{t('call-back__form-send__btn')}</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CallBack