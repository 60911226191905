import React from 'react'

import './Comment.css'
const Comment = ({ text, name, secondText }) => {
  return (
    <div className='comment'>
      <p className="text">{text}</p>
      <div className="comment__bottom">
        <div className="comment__bottom-left">
          <div className="name">{name}</div>
          <p className="second-text">{secondText}</p>
        </div>
        <div className="comment__bottom-right">
          <div className="bnt-prev" />
          <div className="btn-next" />
        </div>
      </div>
    </div>
  )
}

export default Comment