import React from 'react'
import { Header, Achievement, Advantages, Transaction, Comments, Information, Offers, Plus, PopularSections, PopularGoods, CallBack } from '../containers'

const Home = () => {
  return (
    <>
      <Header />
      <Achievement />
      <Offers />
      <Plus />
      <PopularSections />
      <PopularGoods />
      <Advantages />
      <Transaction />
      <Comments />
      <Information />
      <CallBack />
    </>
  )
}

export default Home