import React from 'react'

import CatalogItem from '../../components/CatalogItem/CatalogItem'
import './Catalog.css'

import { catalogD } from '../../data'

const Catalog = () => {
  return (
    <div className='catalog'>
        <div className="container">
            <div className="catalog__items">
                {catalogD.map((item) => 
                  <CatalogItem 
                    key={item.id} 
                    title={item.title} 
                    text={item.text} 
                    name={item.name}
                    image={item?.image}
                  />
                )}
            </div>
        </div>
    </div>
  )
}

export default Catalog