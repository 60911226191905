import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import i18next from 'i18next';

import './Product.css'
import commentsData from '../../data/Comments.json'
import { Table } from '../../components'
import { ColorForm, SendingMailForm, SendingDSECForm, MeasurementsForm, RadioInputForm } from '../../components/Forms'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addProductAction } from '../../store/redusers/basketReduser';
import { enableModalAction } from '../../store/redusers/completeModalReduser';
import { sendMessage } from '../../utlis/sendMessage'

const Product = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()  

  

  const data = useParams()
  const comments = commentsData.filter((x) => x.type === data.catalog)
  
  function isActive(idx) { return view === idx ? 'active' : '' }
  
  const [formState, setFormState] = useState("")
  const [view, setView] = useState(0)
  const [valuePrice, setPrice] = useState(0)
  const [inputData, setInputData] = useState('')

  let isLoad = false;

  const handleInputChange = (e) => {
    setInputData({...inputData, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    switch (i18next.language) {
      case "ru":
        setPrice(props.price.ru)

        if(data.catalog === 'suits'){
          if(inputData.Material === 'Шелк'){
            setPrice(valuePrice + 4000)
            break;
          }
        }

        if(data.subcatalog === 'budosaga'){
          if(inputData.Material === 'Кожа' && inputData.Size >= 45){
            setPrice(props.price.ru + 2500 + 1300)
            break;
          }
          else if(inputData.Size >= 45){
            setPrice(props.price.ru + 1300)
            break;
          }
          else if(inputData.Material === 'Кожа'){
            setPrice(props.price.ru + 2500)
            break;
          }
        }

        break;

      case "en":
        setPrice(props.price.en)

        if(data.catalog === 'suits'){
          if(inputData.Material === 'Silk'){
            setPrice(valuePrice + 30)
            break;
          }
        }

        if(data.subcatalog === 'budosaga'){
          if(inputData.Material === 'Leather' && inputData.Size >= 45){
            setPrice(props.price.en + 20 + 13)
            break;
          }
          else if(inputData.Size >= 45){
            setPrice(props.price.en + 13)
            break;
          }
          else if(inputData.Material === 'Leather'){
            setPrice(props.price.en + 20)
            break;
          }
        }

        break;
    }
  }, [inputData])  

  const getInputData = async (e) => {
    e.preventDefault()
    if(isLoad === true) return;
    isLoad = true
    if(formState === "add-basket-card"){
      dispatch(addProductAction({id: Date.now(), props, inputData, count: 1 }))
    }
    else{
      try{
        sendMessage(`Одиночный заказ:%0A %0A${props.title.ru}%0A${JSON.stringify(inputData, null, '%0A').replace(/[{}"\s]/g, ' ')}`, props.image)
      }catch(ex){
        alert(ex)
      }
    }
    setFormState("")
    dispatch(enableModalAction())
    isLoad = false
  }

  return (
    <div className='product'>
        <div className="container">
            <div className="product__content">
                <div className="product__content-left">
                  <div className="price">
                    {valuePrice}{i18next.language === "ru" ? " руб." : " $"}
                  </div>
                  <div className="image">
                    <img src={props.image} alt="product" />
                  </div>
                  <div className="additional-information">
                    <div className="titles">
                      <div className={`title ${isActive(0)}`} onClick={() => setView(0)}>{t("product_comments")}</div>
                      {(!!props.table && (<div className={`title ${isActive(1)}`} onClick={() => setView(1)} >{t("product_table")}</div>))}
                    </div>
                    <div className="additional-information__content">
                      {
                        {
                          0:<div className='additional-information__content__comments'>
                              {
                                !!comments ?
                                  comments.map((item) => 
                                    <div className='additional-information__content__comment'>
                                      <div className="name">{i18next.language === "ru" ? item.name.ru : item.name.en}</div>
                                      <div className="text">{i18next.language === "ru" ? item.text.ru : item.text.en}</div>
                                    </div>
                                  )
                                  :
                                  <div className="text">{i18next.language === "ru" ? "Нет комментариев" : "No comments"}</div>
                              }
                            </div>,
                          1:
                          (!!props.table && 
                            <div className='additional-information__content__table'>
                              <Table 
                                dHeader={props.table.header} 
                                dBody={props.table.data}/>
                            </div>
                          )
                        }[view]
                      }
                    </div>
                  </div>
                </div>

                <div className="product__content-right">
                  <form onSubmit={getInputData}>
                    { 
                      (props.size === "any" && <MeasurementsForm onChange={handleInputChange} />) 
                      || (props.size?.length > 1 && <RadioInputForm 
                        onChange={handleInputChange} 
                        title={i18next.language === "ru" ? "Выберите размер" : "Select size"} 
                        type="Size"
                        props={props.size}/>)
                    }
                    {
                      (!!props.material && <RadioInputForm 
                        onChange={handleInputChange} 
                        title={i18next.language === "ru" ? "Выберите ткань" : "Select cloth"} 
                        type="Material"
                        props={i18next.language === "ru" ? props.material.ru : props.material.en} />)
                    }
                    {
                      (!!props.typeWusho && <RadioInputForm onChange={handleInputChange} 
                        title={i18next.language === "ru" ? "Выберите вид Ушу" : "Select type of Wushu"} 
                        type="Wushu"
                        props={i18next.language === "ru" ? props.typeWusho.ru : props.typeWusho.en} />)
                    }
                    {
                      ((!!props.sex || inputData.Wushu === "Наньцюань" || inputData.Wushu === "Nanquan") && <RadioInputForm 
                        onChange={handleInputChange} 
                        title={i18next.language === "ru" ? "Выберите пол" : "Select sex"}
                        type="Sex"
                        props={["M", "W"]} />)
                    }
                    { 
                      (props?.color && <ColorForm onChange={handleInputChange} colors={props.color} />) 
                    }
                    {
                      (data.subcatalog === "budosaga" && <RadioInputForm 
                        onChange={handleInputChange}
                        title={i18next.language === "ru" ? "Выберите материал" : "Select material"} 
                        type="Material" 
                        props={i18next.language === "ru" ? ["Кожа", "Кожзам"] : ["Leather", "Synthetic leather"]} />)
                    }
                    { 
                      (i18next.language === "en" && <SendingMailForm onChange={handleInputChange} />)
                      || (props.DSEC === true && <SendingDSECForm onChange={handleInputChange} />) 
                      || <SendingMailForm onChange={handleInputChange} />
                    }

                    <button  
                      className='add-basket' 
                      onClick={() => setFormState("add-basket-card")}>{t('add-to-cart')}
                    </button>
                  </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Product