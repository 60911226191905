import React, { useState } from 'react'

import './Carousel.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import i18next from 'i18next';

export default function Carousel ({data}){
    return(
        <div className="carousel">
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {data.map((item, idx) => <SwiperSlide key={idx}><img src={i18next.language === "ru" ? item.src.ru : item.src.en} alt={item.alt} /></SwiperSlide>)}
            </Swiper>
        </div>
    )
}

