import React from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import './InformationItem.css'
const InformationItem = () => {  
    const { t } = useTranslation()  
    const dataInformation = [
        {
            text: {
                ru: "Оставьте заявку на сайте",
                en: "Leave a request on the website"
            }
        },
        {
            text: {
                ru: "Обсудите с нашим менеджером детали вашего заказа",
                en: "Discuss the details of your order with our manager"
            }
        },
        {
            text: {
                ru: "Укажите верный адрес для доставки",
                en: "Provide the delivery address"
            }
        },
        {
            text: {
                ru: "Оплатите ваш заказ любым способом",
                en: "Pay for your order"
            }
        }
    ]  

    return (
        <>
            <div className="information-dots">
                <div className="information-dot" />
                <div className="information-dot" />
                <div className="information-dot" />
                <div className="information-dot" />
            </div>
            <div className='information-items' >
                {dataInformation.map((item, idx) => 
                    <div key={idx} className={`information-item ${(idx + 1) % 2 === 0 ? 'left' : 'right'}`}>
                        <div className={`information-item__card`} >
                            <div className="title">{`${t('information-items__title')}${idx + 1}`}</div>
                            <p className="text">{i18next.language === 'ru' ? item.text.ru : item.text.en}</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default InformationItem