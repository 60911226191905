import { productsImages } from "../constants";
import { tableSuits, tableShoes } from "./tablesData";
const colorData = [
  {
    id: "white",
    color: "#ffffff",
  },
  {
    id: "black",
    color: "#000000",
  },
  {
    id: "blue",
    color: "#231EE9",
  },
  {
    id: "red",
    color: "#D51515",
  },
  {
    id: "yellow",
    color: "#FFFF00",
  },
  {
    id: "orange",
    color: "#FFA500",
  },
  {
    id: "violet",
    color: "#7F00FF",
  },
  {
    id: "gray",
    color: "#808080",
  },
  {
    id: "gold",
    color: "#FFD700",
  },
  {
    id: "silver",
    color: "#C0C0C0",
  },
  {
    id: "lightBlue",
    color: "#00BFFF",
  },
  {
    id: "pink",
    color: "#FFC0CB",
  },
  {
    id: "green",
    color: "#008000",
  },
  {
    id: "gradient",
    color: "linear-gradient(0deg, rgba(255,0,0,1) 0%, rgba(249,255,0,1) 40%, rgba(255,255,255,1) 80%)"
  }
];
const suitsColorData = [
  {
    id: "1",
    color: "#d14454",
  },
  {
    id: "2",
    color: "#b4d5f8",
  },
  {
    id: "3",
    color: "#1d242f",
  },
  {
    id: "4",
    color: "#e4ece7",
  },
  {
    id: "5",
    color: "#edf2b8",
  },
  {
    id: "6",
    color: "#dd4161",
  },
  {
    id: "7",
    color: "#c6fae4",
  },
  {
    id: "8",
    color: "#d5d1eb",
  },
  {
    id: "9",
    color: "#e4ece7",
  },
  {
    id: "10",
    color: "#b2dbe9",
  },
  {
    id: "11",
    color: "#b1d6fa",
  },
  {
    id: "12",
    color: "#c8d0d4",
  },
  {
    id: "13",
    color: "#6e6d83",
  },
  {
    id: "14",
    color: "#c3d3e9",
  },
  {
    id: "15",
    color: "#ddbfe9",
  },
  {
    id: "16",
    color: "#d4d9e1",
  },
  {
    id: "17",
    color: "#91304c",
  },
  {
    id: "18",
    color: "#b1b4b7",
  },
  {
    id: "19",
    color: "#e9cfc8",
  },
  {
    id: "20",
    color: "#bcb7b5",
  },
  {
    id: "21",
    color: "#abd6c3",
  },
  {
    id: "22",
    color: "#e7e7f7",
  },
  {
    id: "23",
    color: "#b3cfe5",
  },
  {
    id: "24",
    color: "#b09ac4",
  },
  {
    id: "25",
    color: "#c4b593",
  },
  {
    id: "26",
    color: "#ae2d46",
  },
  {
    id: "27",
    color: "#2f749b",
  },
  {
    id: "28",
    color: "#574746",
  },
  {
    id: "29",
    color: "#d7e8f4",
  },
  {
    id: "30",
    color: "#de45a5",
  },
  {
    id: "31",
    color: "#93e7fe",
  },
  {
    id: "32",
    color: "#adb5d7",
  },
  {
    id: "33",
    color: "#b1a695",
  },
  {
    id: "34",
    color: "#cfc7e7",
  },
  {
    id: "35",
    color: "#2bc6fd",
  },
  {
    id: "36",
    color: "#81eaf6",
  },
  {
    id: "37",
    color: "#a9a88d",
  },
  {
    id: "38",
    color: "#7fc3ed",
  },
  {
    id: "39",
    color: "#d3c7db",
  },
  {
    id: "40",
    color: "#4bc4de",
  },
  {
    id: "41",
    color: "#3b363a",
  },
  {
    id: "42",
    color: "#082da5",
  },
  {
    id: "43",
    color: "#9fc0e5",
  },
  {
    id: "44",
    color: "#9ba7ae",
  },
  {
    id: "45",
    color: "#9b72af",
  },
  {
    id: "46",
    color: "#03708e",
  },
  {
    id: "47",
    color: "#b7bdf8",
  },
  {
    id: "48",
    color: "#f2a55d",
  },
  {
    id: "49",
    color: "#3b1e43",
  },
  {
    id: "50",
    color: "#c2d885",
  },
  {
    id: "51",
    color: "#efcf4f",
  },
  {
    id: "52",
    color: "#64c77b",
  },
  {
    id: "53",
    color: "#eea8ed",
  },
  {
    id: "54",
    color: "#06aad0",
  },
  {
    id: "55",
    color: "#9f6358",
  },
  {
    id: "56",
    color: "#c3c7d2",
  },
  {
    id: "57",
    color: "#c39b6b",
  },
  {
    id: "58",
    color: "#501294",
  },
  {
    id: "59",
    color: "#c8d7eb",
  },
  {
    id: "60",
    color: "#b8a582",
  },
  {
    id: "61",
    color: "#118a5b",
  },
  {
    id: "62",
    color: "#92c0eb",
  },
  {
    id: "63",
    color: "#a3d688",
  },
  {
    id: "64",
    color: "#ff7f9d",
  },
  {
    id: "65",
    color: "#ac97dd",
  },
  {
    id: "66",
    color: "#677b6c",
  },
  {
    id: "67",
    color: "#f098b1",
  },
  {
    id: "68",
    color: "#61f1cb",
  },
  {
    id: "69",
    color: "#c6fb95",
  },
  {
    id: "70",
    color: "#fd8565",
  },
  {
    id: "71",
    color: "#bca8fa",
  },
  {
    id: "72",
    color: "#1ebe91",
  },
  {
    id: "73",
    color: "#a771a2",
  },
  {
    id: "74",
    color: "#72ad72",
  },
  {
    id: "75",
    color: "#dcd1a3",
  },
  {
    id: "76",
    color: "#9bc6ba",
  },
  {
    id: "77",
    color: "#ea5cb7",
  },
  {
    id: "78",
    color: "#03afa9",
  },
  {
    id: "79",
    color: "#29216f",
  },
  {
    id: "80",
    color: "#c2e7af",
  },
  {
    id: "81",
    color: "#534149",
  },
  {
    id: "82",
    color: "#dcd6fe",
  },
  {
    id: "83",
    color: "#4e6282",
  },
  {
    id: "84",
    color: "#169289",
  },
  {
    id: "85",
    color: "#da74bb",
  },
  {
    id: "86",
    color: "#3fc0d5",
  },
  {
    id: "87",
    color: "#986488",
  },
  {
    id: "88",
    color: "#bf5ba3",
  },
  {
    id: "89",
    color: "#703d7e",
  },
  {
    id: "90",
    color: "#1b274c",
  },
  {
    id: "91",
    color: "#800d9f",
  },
  {
    id: "92",
    color: "#0e4878",
  },
  {
    id: "93",
    color: "#8760b6",
  },
  {
    id: "94",
    color: "#cabca4",
  },
  {
    id: "95",
    color: "#6a7578",
  },
  {
    id: "96",
    color: "#f3a59d",
  },
  {
    id: "97",
    color: "#1a254d",
  },
  {
    id: "98",
    color: "#bbb5a3",
  },
  {
    id: "99",
    color: "#9eaec9",
  },
  {
    id: "100",
    color: "#8a9276",
  },
  {
    id: "101",
    color: "#133172",
  },
  {
    id: "102",
    color: "#c3d4e7",
  },
  {
    id: "103",
    color: "#7791b3",
  },
  {
    id: "104",
    color: "#2f2959",
  },
  {
    id: "105",
    color: "#c0d9f7",
  },
  {
    id: "106",
    color: "#a5b6ca",
  },
  {
    id: "107",
    color: "#c0c3c2",
  },
  {
    id: "108",
    color: "#90c1d9",
  },
  {
    id: "109",
    color: "#cdcabd",
  },
  {
    id: "110",
    color: "#cbd2e9",
  },
  {
    id: "111",
    color: "#706f72",
  },
  {
    id: "112",
    color: "#88a9de",
  },
  {
    id: "113",
    color: "#ef6977",
  },
  {
    id: "114",
    color: "#6096ed",
  },
  {
    id: "115",
    color: "#234351",
  },
  {
    id: "116",
    color: "#4a1cad",
  },
  {
    id: "117",
    color: "#d628aa",
  },
  {
    id: "118",
    color: "#832c55",
  },
  {
    id: "119",
    color: "#f1beef",
  },
  {
    id: "120",
    color: "#be30a4",
  },
  {
    id: "121",
    color: "#1a4750",
  },
  {
    id: "122",
    color: "#832472",
  },
  {
    id: "123",
    color: "#0668d4",
  },
  {
    id: "124",
    color: "#612847",
  },
  {
    id: "125",
    color: "#6e75d6",
  },
  {
    id: "126",
    color: "#161f9a",
  },
  {
    id: "127",
    color: "#5e545c",
  },
  {
    id: "128",
    color: "#e8f5d3",
  },
  {
    id: "129",
    color: "#c9c6c9",
  },
  {
    id: "130",
    color: "#c32a92",
  },
  {
    id: "131",
    color: "#182f69",
  },
  {
    id: "132",
    color: "#3e3d44",
  },
  {
    id: "133",
    color: "#081464",
  },
  {
    id: "134",
    color: "#43214a",
  },
  {
    id: "135",
    color: "#1a22b3",
  },
  {
    id: "136",
    color: "#432a47",
  },
  {
    id: "137",
    color: "#a9c4e4",
  },
  {
    id: "138",
    color: "#077ec0",
  },
  {
    id: "139",
    color: "#3d605d",
  },
  {
    id: "140",
    color: "#4b6a74",
  },
  {
    id: "141",
    color: "#a9563e",
  },
  {
    id: "142",
    color: "#ff89b1",
  },
  {
    id: "143",
    color: "#feaa5e",
  },
  {
    id: "144",
    color: "#a9fe8b",
  },
]
const vipSuitsColorData = [
  {
    id: "1",
    color: "#c6222d"
  },
  {
    id: "2",
    color: "#2dacd7"
  },
  {
    id: "3",
    color: "#232323"
  },
  {
    id: "4",
    color: "#fcfaed"
  },
  {
    id: "5",
    color: "#fe852c"
  },
  {
    id: "6",
    color: "#80c1eb"
  },
  {
    id: "7",
    color: "#b52234"
  },
  {
    id: "8",
    color: "#e5b249"
  },
  {
    id: "9",
    color: "#65414d"
  },
  {
    id: "10",
    color: "#fafc6d"
  },
  {
    id: "11",
    color: "#0e1a90"
  },
  {
    id: "12",
    color: "#b9eae7"
  },
  {
    id: "13",
    color: "#9d2735"
  },
  {
    id: "14",
    color: "#f4d7dc"
  },
  {
    id: "15",
    color: "#937968"
  },
  {
    id: "16",
    color: "#221527"
  },
  {
    id: "17",
    color: "#a2dee9"
  },
  {
    id: "18",
    color: "#a02d1a"
  },
  {
    id: "19",
    color: "#93a982"
  },
  {
    id: "20",
    color: "#dda6a3"
  },
  {
    id: "21",
    color: "#088936"
  },
  {
    id: "22",
    color: "#694c4e"
  },
  {
    id: "23",
    color: "#c81092"
  },
  {
    id: "24",
    color: "#dfb970"
  },
  {
    id: "25",
    color: "#7c5029"
  },
  {
    id: "26",
    color: "#dfe0d8"
  },
  {
    id: "27",
    color: "#bbbbbb"
  },
  {
    id: "28",
    color: "#a596f1"
  },
  {
    id: "29",
    color: "#cbbe9b"
  },
  {
    id: "30",
    color: "#490010"
  },
  {
    id: "31",
    color: "#f3c9b1"
  },
  {
    id: "32",
    color: "#f9576e"
  },
  {
    id: "33",
    color: "#8ad547"
  },
  {
    id: "34",
    color: "#725397"
  },
  {
    id: "35",
    color: "#ffce33"
  },
  {
    id: "36",
    color: "#7bbc94"
  },
  {
    id: "37",
    color: "#e7900f"
  },
  {
    id: "38",
    color: "#f794d4"
  },
  {
    id: "39",
    color: "#595a52"
  },
  {
    id: "40",
    color: "#f2f2f2"
  },
  {
    id: "41",
    color: "#352224"
  },
  {
    id: "42",
    color: "#b94e2e"
  },
  {
    id: "43",
    color: "#273244"
  },
  {
    id: "44",
    color: "#b3eeaa"
  },
  {
    id: "45",
    color: "#fe967b"
  },
  {
    id: "46",
    color: "#91a738"
  },
  {
    id: "47",
    color: "#f7d0e2"
  },
  {
    id: "48",
    color: "#98adca"
  },
  {
    id: "49",
    color: "#ad9358"
  },
  {
    id: "50",
    color: "#262d73"
  },
  {
    id: "51",
    color: "#9b2464"
  },
  {
    id: "52",
    color: "#214f2a"
  },
  {
    id: "53",
    color: "#623183"
  },
  {
    id: "54",
    color: "#1a6372"
  },
  {
    id: "55",
    color: "#ef37b9"
  },
  {
    id: "56",
    color: "#282c4f"
  },
  {
    id: "57",
    color: "#f32d2c"
  },
  {
    id: "58",
    color: "#585858"
  },
  {
    id: "59",
    color: "#a99ea2"
  },
  {
    id: "60",
    color: "#f4f6e8"
  },
]

const productsD = {
  suits: [
    {
      id: 1,
      title: {
        ru: "Костюм Чанцюань по новым требованиям ФУР",
        en: "Changquan plain suit",
      },
      text: {
        ru: ["Искусственный шелк", "Размер: 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175", "Белый, черный, синий, красный"],
        en: ["Artificial silk", "Size: 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175", "White, black, blue, red."],
      },
      price: {
        ru: 4000,
        en: 49
      },
      image: productsImages.suits_1,
      size: [120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175],
      color: [colorData[0], colorData[1], colorData[2], colorData[3]],
      DSEC: true,
      table:{
        header: tableSuits.headerSuits,
        data: tableSuits.dataSuits
      }
    },
    {
      id: 2,
      title: {
        ru: "Костюм по индивидуальным меркам",
        en: "Customozable suit",
      },
      text: {
        ru: [
          "Искусственный шелк",
          "Изготовление по индивидуальным меркам",
          "Любой цвет на выбор",
        ],
        en: [
          "Synthetic silk",
          "Manufacture to individual measurements",
          "Any color of your choice"
        ],
      },
      price: {
        ru: 6500,
        en: 70
      },
      image: productsImages.suits_2,
      size: "any",
      color: suitsColorData,
    },
    {
      id: 3,
      title: {
        ru: "Костюм чанцюань по индивидуальным меркам",
        en: "Plain changquan suit",
      },
      text: {
        ru: [
          "Натуральный шелк",
          "Изготавливается по индивидуальным меркам",
          "Цвет: белый, черный, синий, красный",
        ],
        en: [
          "Natural silk",
          "Made to individual measurements",
          "Color: white, black, blue, red",
        ],
      },
      price: {
        ru: 13000,
        en: 148
      },
      image: productsImages.suits_3,
      size: "any",
      color: [colorData[0], colorData[1], colorData[2], colorData[3]],
    },
    {
      id: 4,
      title: {
        ru: "Костюм Наньцюань по новым требованиям ФУР",
        en: "Ready-made men's nanquan plain suits",
      },
      text: {
        ru: [
          "Искусственный шелк",
          "Размер: 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175",
          "Цвет: черный, синий, красный",
        ],
        en: [
          "Synthetic silk",
          "Size: 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175",
          "Color: black, blue, red",
        ],
      },
      price: {
        ru: 4000,
        en: 49
      },
      image: productsImages.suits_4,
      size: [120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175],
      sex: ["M", "W"],
      color: [colorData[1], colorData[2], colorData[3]],
      DSEC: true,
      table:{
        header: tableSuits.headerSuits,
        data: tableSuits.dataSuits
      }
    },
    {
      id: 5,
      title: {
        ru: "Костюм для наньцюань по индивидуальным меркам",
        en: "Plain nanquan suit",
      },
      text: {
        ru: [
          "Натуральный шелк",
          "Изготавливается по индивидуальным меркам",
          "Черный, синий, красный",
        ],
        en: [
          "Natural silk",
          "Made to individual measurements",
          "Black, blue, red"
        ],
      },
      price: {
        ru: 13000,
        en: 148
      },
      image: productsImages.suits_5,
      size: "any",
      sex: ["M", "W"],
      color: [colorData[1], colorData[2], colorData[3]],
    },
    {
      id: 7,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_7,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
      table:{
        header: tableSuits.headerSuits,
        data: tableSuits.dataSuits
      }
    },
    {
      id: 8,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_8,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
      table:{
        header: tableSuits.headerSuits,
        data: tableSuits.dataSuits
      }
    },
    {
      id: 9,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_9,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 10,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_10,
      size: "any",
      color: colorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 11,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_11,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 12,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_12,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 13,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_13,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 14,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_14,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 15,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_15,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 16,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_16,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 18,
      title: {
        ru: "Костюм для тайцзицюань по новым требованиям ФУР",
        en: "Ready-made taijiquan plain suit",
      },
      text: {
        ru: [
          "Искусственный шелк",
          "Размер: 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175",
          "Цвет: черный, синий, красный",
        ],
        en: [
          "Synthetic silk",
          "Size: 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175",
          "Color: black, blue, red",
        ],
      },
      price: {
        ru: 4000,
        en: 49
      },
      image: productsImages.suits_18,
      size: [120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175],
      color: [colorData[0], colorData[1], colorData[2], colorData[3]],
      DSEC: true,
      table:{
        header: tableSuits.headerSuits,
        data: tableSuits.dataSuits
      }
    },
    {
      id: 20,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_20,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 21,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_21,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 22,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_22,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 23,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_23,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 24,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_24,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 25,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_25,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 26,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_26,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 27,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_27,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 28,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_28,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 29,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_29,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 30,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_30,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 31,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_31,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 32,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_32,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 33,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_33,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 34,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_34,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 35,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_35,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 36,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_36,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 37,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_37,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 38,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_38,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 39,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_39,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 40,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_40,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 41,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_41,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 42,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_42,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 43,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_43,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 44,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_44,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 45,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_45,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 46,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_46,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 47,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_47,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 48,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_48,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 49,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_49,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 50,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_50,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 51,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_51,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 52,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_52,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 53,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_53,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 54,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_54,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 55,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_55,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 56,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_56,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 57,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_57,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 58,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_58,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 59,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_59,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 60,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_60,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 61,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_61,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 62,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_62,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 63,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_63,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 64,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_64,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 65,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_65,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 66,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_66,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 67,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_67,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 68,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_68,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 69,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_69,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 70,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_70,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 71,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_71,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 72,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_72,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 73,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_73,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 74,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_74,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 75,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_75,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 76,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_76,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 77,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_77,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 78,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_78,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 79,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_79,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 80,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_80,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 81,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_81,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 82,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_82,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 83,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_83,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 84,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_84,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 85,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_85,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 86,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_86,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 87,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_87,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 88,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_88,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 89,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_89,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 90,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_90,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 91,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_91,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 92,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_92,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },
    {
      id: 19,
      title: {
        ru: "Костюм для ушу на заказ по вашим меркам",
        en: "Customizable basic wushu suit",
      },
      text: {
        ru: [
          "3 вида материала на выбор",
          "Любой вид спортивного ушу",
          "Любой цвет вышивки и костюма",
        ],
        en: [
          "3 types of material to choose from",
          "Any kind of sports wushu",
          "Any color of embroidery and suit",
        ],
      },
      price: {
        ru:  11500,
        en: 155
      },
      image: productsImages.suits_19,
      size: "any",
      color: suitsColorData,
      material: 
      {
        ru: ["Шелк", "Исскуственный шелк", "Шелк с градиентом"],
        en: ["Silk", "Synthetic silk", "Silk with gradient"]
      },
      typeWusho:{
        ru: ["Чанцюань", "Наньцюань", "Тайцзицюань"],
        en: ["Changquan", "Nanquan", "Taijiquan"]
      },
    },

  ],
  vipsuits: [
    {
      id: 1,
      title: {
        ru: "Однотонный костюм VIP для чанцюань",
        en: "Plain VIP Suit for Changquan",
      },
      text: {
        ru: [
          "Только натуральный шелк",
          "4 цвета материала на выбор",
          "Шьется по индивидуальным меркам",
        ],
        en: [
          "Only natural silk",
          "4 colors of material to choose from",
          "Sewn to individual measurements",
        ],
      },
      price: {
        ru: 20000,
        en: 215
      },
      image: productsImages.vipSuits_1,
      size: "any",
      color: [colorData[0], colorData[1], colorData[2], colorData[3]],
    },
    {
      id: 2,
      title: {
        ru: "Однотонный костюм VIP для наньцюань",
        en: "Plain VIP Suit for Nanquan",
      },
      text: {
        ru: [
          "Только натуральный шелк",
          "3 цвета материала на выбор",
          "Шьется по индивидуальным меркам",
        ],
        en: [
          "Only natural silk",
          "3 colors of material to choose from",
          "Sewn to individual measurements",
        ],
      },
      price: {
        ru: 20000,
        en: 215
      },
      image: productsImages.vipSuits_2,
      size: "any",
      color: [colorData[1], colorData[2], colorData[3]],
      sex: ["M", "W"],
    },
    {
      id: 3,
      title: {
        ru: "Костюм VIP с вышивкой",
        en: "VIP suit with embroidery",
      },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_3,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 4,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_4,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 5,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_5,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 6,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_6,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 7,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_7,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 8,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_8,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 9,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_9,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 10,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_10,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 11,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_11,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 12,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_12,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 13,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_13,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 14,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_14,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 15,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_15,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 16,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_16,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 17,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_17,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 18,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_18,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 19,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_19,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 20,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_20,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 21,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_21,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 22,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_22,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 23,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 36000,
        en: 400
      },
      image: productsImages.vipSuits_23,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 24,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_24,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 25,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_25,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 26,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_26,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 27,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_27,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 28,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_28,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 29,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_29,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 30,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_30,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 31,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_31,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 32,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_32,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 33,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_33,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 34,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_34,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 35,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_35,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 36,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_36,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 38,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_38,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 39,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_39,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 40,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_40,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 41,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_41,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 42,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_42,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 43,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_43,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 44,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_44,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 45,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_45,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 46,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_46,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 47,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_47,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 48,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_48,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 49,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_49,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 50,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_50,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 51,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_51,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 52,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_52,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 53,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_53,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 54,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_54,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 55,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_55,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 56,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_56,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 57,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_57,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 58,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_58,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 59,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_59,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 60,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_60,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 61,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_61,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 62,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_62,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 63,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_63,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 64,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_64,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 65,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_65,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 66,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_66,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 67,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_67,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 68,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_68,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 69,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_69,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 70,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_70,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 71,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_71,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 72,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_72,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 73,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_73,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 74,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_74,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 75,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_75,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 76,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_76,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 77,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_77,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 78,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_78,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 79,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_79,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 80,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_80,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 81,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_81,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 82,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_82,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 83,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_83,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 84,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_84,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 85,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_85,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 86,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_86,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 87,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_87,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 88,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_88,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 89,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_89,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 90,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_90,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 91,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_91,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 92,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_92,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 93,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_93,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 94,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_94,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 95,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_95,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 96,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_96,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 97,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_97,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 98,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_98,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 99,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_99,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 100,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_100,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 101,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_101,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 102,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_102,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 103,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_103,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 104,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_104,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 105,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_105,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 106,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 36000,
        en: 400
      },
      image: productsImages.vipSuits_106,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 107,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_107,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 108,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_108,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 109,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_109,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 110,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_110,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 111,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_111,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 112,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_112,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 113,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 36000,
        en: 400
      },
      image: productsImages.vipSuits_113,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 114,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 32000,
        en: 360
      },
      image: productsImages.vipSuits_114,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 115,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_115,
      size: "any",
      color: vipSuitsColorData,
    },
    {
      id: 116,
      title: { ru: "Костюм VIP", en: "VIP suit with embroidery" },
      text: {
        ru: [
          "Только натуральный шелк",
          "Более 4-х цветов в вышивке",
          "Любой цвет костюма и вышивки",
          "Любой вид спортивного ушу",
        ],
        en: [
          "Only natural silk",
          "More than 4 colors in embroidery",
          "Any color of suit and embroidery",
          "Any kind of sports wushu",
        ],
      },
      price: {
        ru: 30000,
        en: 330
      },
      image: productsImages.vipSuits_116,
      size: "any",
      color: vipSuitsColorData,
    },
  ],
  shoes: [
    {
      id: 1,
      name: "feiyue",
      title: { ru: "Фейюэ", en: "Feiyue" },
      text: {
        ru: ["Материал: хлопок", "Размеры: 25-47", "Цвета: черный, белый"],
        en: ["Material:", "Size: 23-47", "Color: black, white"],
      },
      goods: [
        {
          id: 1,
          title: {
            ru: "Кеды Фейюэ на шнуровке",
            en: "Fabric shoes Feiyue",
          },
          text: {
            ru: ["Материал: хлопок", "Размеры: 27-47", "Цвета: черный и белый"],
            en: ["Material: cotton", "Size: 27-47", "Color: black, white"],
          },
          price: {
            ru: 1200,
            en: 12
          },
          image: productsImages.feiyue_1,
          size: [
            27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45, 46, 47,
          ],
          color: [colorData[0], colorData[1]],
          DSEC: true,
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataFeiyue
          }
        },
        {
          id: 2,
          title: {
            ru: "Детские тканевые кеды Фейюе на липучке",
            en: "Feiyue children's fabric shoes with Velcro",
          },
          text: {
            ru: ["Материал: хлопок", "Размеры: 25-36", "Цвета: черный и белый"],
            en: ["Material: cotton", "Size: 25-46", "Color: black, white"],
          },
          price: {
            ru: 2500,
            en: 19
          },
          image: productsImages.feiyue_2,
          size: [
            25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36
          ],
          color: [colorData[0], colorData[1]],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataFeiyueChildren
          }
        },
      ],
      image: productsImages.feiyue,
    },
    {
      id: 2,
      name: "budosaga",
      title: { ru: "Будосага", en: "Budosaga" },
      text: {
        ru: ["Материал: кожа", "Размеры: 28-47", "Цвета: любой"],
        en: ["Material: leather", "Size: 28-47", "Color: any"],
      },
      goods: [
        {
          id: 1,
          title: { ru: "Кеды Будосага", en: "Budosaga shoes" },
          text: {
            ru: [
              "Материал: кожа и микрофибра",
              "Размеры: 28-47",
              "Цвет: белый, черный, синий, красный, фиолетовый, золотой, серебряный",
            ],
            en: [
              "Material: leather and microfiber",
              "Size: 28-47",
              "Color: black, red, white, gold, blue, purple, silver",
            ],
          },
          price: {
            ru: 3500,
            en: 40
          },
          image: productsImages.budosaga_1,
          size: [
            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45, 46, 47,
          ],
          color: [
            colorData[0],
            colorData[1],
            colorData[2],
            colorData[3],
            colorData[6],
            colorData[8],
            colorData[9],
          ],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataBudosaga
          }
        },
        {
          id: 2,
          title: {
            ru: "Льняные кеды Будосага",
            en: "Budosaga linen shoes",
          },
          text: {
            ru: ["Материал: лен", "Размеры: 28-47", "Цвет: черный, серый"],
            en: ["Material: linen", "Size: 28-47", "Color: black, grey"],
          },
          price: {
            ru: 3500,
            en: 55
          },
          image: productsImages.budosaga_2,
          size: [
            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45, 46, 47,
          ],
          color: [colorData[1], colorData[7]],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataBudosaga
          }
        },
        {
          id: 3,
          title: {
            ru: "Кеды Будосага с вышивкой",
            en: "Budosaga shoes with embroidery",
          },
          text: {
            ru: ["Материал: кожа", "Размеры: 28-47", "Цвет: любой"],
            en: ["Material: leather", "Size: 28-47", "Color: any"],
          },
          price: {
            ru: 5000,
            en: 60
          },
          image: productsImages.budosaga_3,
          size: [
            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45, 46, 47,
          ],
          color: colorData,
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataBudosaga
          }
        },
        {
          id: 4,
          title: {
            ru: "Кеды Будосага с вышивкой",
            en: "Budosaga shoes with embroidery",
          },
          text: {
            ru: ["Материал: кожа", "Размеры: 28-47", "Цвет: любой"],
            en: ["Material: leather", "Size: 28-47", "Color: any"],
          },
          price: {
            ru: 5500,
            en: 85
          },
          image: productsImages.budosaga_4,
          size: [
            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45, 46, 47,
          ],
          color: colorData,
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataBudosaga
          }
        },
        {
          id: 5,
          title: {
            ru: "Кеды Будосага с вышивкой",
            en: "Budosaga shoes with embroidery",
          },
          text: {
            ru: ["Материал: кожа", "Размеры: 28-47", "Цвет: любой"],
            en: ["Material: leather", "Size: 28-47", "Color: any"],
          },
          price: {
            ru: 5500,
            en: 85
          },
          image: productsImages.budosaga_5,
          size: [
            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45, 46, 47,
          ],
          color: colorData,
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataBudosaga
          }
        },
        {
          id: 6,
          title: {
            ru: "Кеды Будосага с вышивкой",
            en: "Budosaga shoes with embroidery",
          },
          text: {
            ru: ["Материал: кожа", "Размеры: 28-47", "Цвет: любой"],
            en: ["Material: leather", "Size: 28-47", "Color: any"],
          },
          price: {
            ru: 5500,
            en: 85
          },
          image: productsImages.budosaga_6,
          size: [
            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45, 46, 47,
          ],
          color: colorData,
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataBudosaga
          }
        },
      ],
      image: productsImages.budosaga,
    },
    {
      id: 3,
      name: "love-wushu",
      title: { ru: "Люблю Ушу", en: "Love Wushu" },
      text: {
        ru: [
          "Материал: микрофибра",
          "Размеры: 29-44",
          "Цвета: белый, черный, красный, золотой",
        ],
        en: ["Material: microfiber", "Size: 29-44", "Color: white, red, black"],
      },
      goods: [
        {
          id: 1,
          title: { ru: "Кеды Люблю Ушу", en: "Love Wushu shoes" },
          text: {
            ru: [
              "Материал: микрофибра",
              "Размер: 29-44",
              "Цвет: белый, черный, синий, красный, золотой",
            ],
            en: [
              "Material: microfiber",
              "Size: 29-44",
              "Color: red, gold, white, black, blue",
            ],
          },
          price: {
            ru: 2000,
            en: 30
          },
          image: productsImages.loveWushu_1,
          size: [
            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44
          ],
          color: [colorData[0], colorData[1], colorData[2], colorData[3], colorData[8]],
          DSEC: true,
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataLoveWushu
          }
        },
        {
          id: 2,
          title: {
            ru: "Кеды Люблю Ушу с перфорацией",
            en: "Love Wushu shoes with perforations",
          },
          text: {
            ru: [
              "Материал: микрофибра",
              "Размер: 29-44",
              "Цвет: белый, черный",
            ],
            en: ["Material: microfiber", "Size: 29-44", "Color: white, black"],
          },
          price: {
            ru: 2000,
            en: 30
          },
          image: productsImages.loveWushu_2,
          size: [
            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44
          ],
          color: [colorData[0], colorData[1]],
          DSEC: true,
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataLoveWushu
          }
        },
      ],
      image: productsImages.loveWushu,
    },
    {
      id: 4,
      name: "gwc",
      title: { ru: "Великая Китайская стена", en: "Great Wall of China" },
      text: {
        ru: [
          "Материал: кожа",
          "Размеры: 33-45",
          "Цвета: черный, синий, красный, золотой, серебряный",
        ],
        en: [
          "Material: leather",
          "Size: 33-45",
          "Color: blue, black, red, silver, gold",
        ],
      },
      goods: [
        {
          id: 1,
          title: {
            ru: "Кеды Великая Китайская стена",
            en: "shoes Great Wall of China",
          },
          text: {
            ru: [
              "Материал: кожа",
              "Размер: 33-45",
              "Цвет: черный, синий, красный, золотой, серебряный",
            ],
            en: [
              "Material: leather",
              "Size: 33-45",
              "Color: red, gold, blue, silver, black",
            ],
          },
          price: {
            ru: 4200,
            en: 45
          },
          image: productsImages.gwc_1,
          size: [
            33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45
          ],
          color: [colorData[1], colorData[2], colorData[3], colorData[8], colorData[9]],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataGWC
          }
        },
        {
          id: 2,
          title: {
            ru: "Кеды Великая Китайская стена",
            en: "shoes Great Wall of China",
          },
          text: {
            ru: [
              "Материал: кожа",
              "Размер: 33-45",
              "Цвет: черный, синий, красный, золотой, серебряный",
            ],
            en: [
              "Material: leather",
              "Size: 33-45",
              "Color: red, gold, blue, silver, black",
            ],
          },
          price: {
            ru: 5400,
            en: 65
          },
          image: productsImages.gwc_2,
          size: [
            33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
            44, 45
          ],
          color: [colorData[1], colorData[2], colorData[3], colorData[8], colorData[9]],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataGWC
          }
        },
      ],
      image: productsImages.gwc,
    },
    {
      id: 5,
      name: "wu-yue",
      title: { ru: "У ЮЭ", en: "Wu Yue" },
      text: {
        ru: [
          "Материал: кожа",
          "Размеры: 33-45",
          "Цвета: белый, черный, синий, красный, золотой, серебряный, голубой",
        ],
        en: [
          "Material: leather",
          "Size: 33-45",
          "Color: red, blue, light blue, gold, silver, white, black",
        ],
      },
      goods: [
        {
          id: 1,
          title: {
            ru: "Блестящие кеды У юэ",
            en: "Blistering Wu Yue shoes",
          },
          text: {
            ru: [
              "Материал: кожа",
              "Размер: 33-45",
              "Цвета: черный, синий, красный, золотой, серебряный, голубой",
            ],
            en: [
              "Material: leather",
              "Size: 33-45",
              "Color: red, blue, light blue, gold, silver, black",
            ],
          },
          price: {
            ru: 6500,
            en: 100
          },
          image: productsImages.wuYue_1,
          size: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
          color: [
            colorData[1],
            colorData[2],
            colorData[3],
            colorData[8],
            colorData[9],
            colorData[10],
          ],
        },
        {
          id: 2,
          title: {
            ru: "Кожаные кеды У юэ",
            en: "Leather shoes Wu Yue",
          },
          text: {
            ru: ["Материал: кожа", "Размер: 33-45", "Цвета: белый, красный"],
            en: ["Material: leather", "Size: 33-45", "Color: white, red"],
          },
          price: {
            ru: 5200,
            en: 80
          },
          image: productsImages.wuYue_2,
          size: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
          color: [colorData[0], colorData[3]],
        },
      ],
      image: productsImages.wuYue,
    },
    {
      id: 6,
      name: "do-win",
      title: { ru: "Do-win", en: "Do-win" },
      text: {
        ru: ["Материал: кожа", "Размеры: 35-44", "Цвета: белый, черный"],
        en: ["Material: leather", "Size: 35-44", "Color: white, black"],
      },
      goods: [
        {
          id: 1,
          title: {
            ru: "Кеды Do-win для Тайцзи",
            en: "Do-win shoes for Taiji",
          },
          text: {
            ru: ["Материал: кожа", "Размер: 35-44", "Цвет: белый, черный"],
            en: ["Material: leather", "Size: 35-44", "Color: white, black"],
          },
          price: {
            ru: 5200,
            en: 80
          },
          image: productsImages.doWin_1,
          size: [35, 36, 37, 38, 39, 40, 41, 42, 43, 44],
          color: [colorData[0], colorData[1]],
        },
        {
          id: 2,
          title: {
            ru: "Кеды Do-win для спортивного Ушу",
            en: "Do-win shoes for sports Wushu",
          },
          text: {
            ru: ["Материал: кожа", "Размер: 35-44", "Цвет: белый, черный"],
            en: ["Material: leather", "Size: 35-44", "Color: white, black"],
          },
          price: {
            ru: 5200,
            en: 80
          },
          image: productsImages.doWin_2,
          size: [35, 36, 37, 38, 39, 40, 41, 42, 43, 44],
          color: [colorData[0], colorData[1]],
        },
      ],
      image: productsImages.doWin,
    },
    {
      id: 7,
      name: "daehentong",
      title: { ru: "Даехэнтун", en: "Daehentong dayehengtong" },
      text: {
        ru: [
          "Материал: кожзам и микрофибра",
          "Размеры: 29-45",
          "Цвета: белый, черный, синий, красный, желтый, оранжевый, фиолетовый,, золотой, серебряный, голубой, розовый",
        ],
        en: [
          "Material: leather and microfiber",
          "Size: 29-45",
          "Color: any",
        ],
      },
      goods: [
        {
          id: 1,
          title: { ru: "Кеды Даехэнтун", en: "Dayehengtong shoes" },
          text: {
            ru: [
              "Материал: кожзам",
              "Размер: 33-45",
              "Цвета: черный, красный, оранжевый, фиолетовый, золотой, серебряный, голубой, розовый",
            ],
            en: [
              "Material: synthetic leather",
              "Size: 33-45",
              "Colors: black, red, blue, purple, pink, orange, gold, silver",
            ],
          },
          price: {
            ru: 5200,
            en: 65
          },
          image: productsImages.daehentong_1,
          size: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
          color: [
            colorData[1],
            colorData[3],
            colorData[5],
            colorData[6],
            colorData[8],
            colorData[9],
            colorData[10],
            colorData[11],
          ],
        },
        {
          id: 2,
          title: { ru: "Кеды Даехэнтун", en: "Dayehengtong shoes" },
          text: {
            ru: [
              "Материал: кожзам",
              "Размер: 33-45",
              "Цвета: черный, синий, красный, фиолетовый, розовый",
            ],
            en: [
              "Material: synthetic leather",
              "Size: 33-45",
              "Colors: black, blue, red, purple, pink",
            ],
          },
          price: {
            ru: 4200,
            en: 45
          },
          image: productsImages.daehentong_2,
          size: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
          color: [colorData[1], colorData[2], colorData[3], colorData[6], colorData[11]],
        },
        {
          id: 3,
          title: { ru: "Кеды Даехэнтун", en: "Dayehengtong shoes" },
          text: {
            ru: [
              "Материал: кожзам",
              "Размер: 29-45",
              "Цвета: белый, черный, синий, красный, желтый, золотой, серебряный, розовый",
            ],
            en: [
              "Material: synthetic leather",
              "Size: 29-45",
              "Colors: white, black, blue, red, yellow, pink, gold, silver",
            ],
          },
          price: {
            ru: 3500,
            en: 32
          },
          image: productsImages.daehentong_3,
          size: [
            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
          ],
          color: [
            colorData[0],
            colorData[1],
            colorData[2],
            colorData[3],
            colorData[4],
            colorData[8],
            colorData[9],
            colorData[11],
          ],
        },
      ],
      image: productsImages.daehentong,
    },
    {
      id: 8,
      name: "asics",
      title: { ru: "Асикс", en: "Asics" },
      text: {
        ru: [
          "Материал: кожа",
          "Размеры: 29-45",
          "Цвета: белый, черный",
        ],
        en: [
          "Material: leather",
          "Size: 29-45",
          "Color: white, black",
        ],
      },
      goods: [
        {
          id: 1,
          title: { ru: "Асикс", en: "Asics" },
          text: {
            ru: [
              "Материал: кожа",
              "Размер: 22-28",
            ],
            en: [
              "Material: leather",
              "Size: 22-28",
            ],
          },
          price: {
            ru: 14500,
            en: 165
          },
          image: productsImages.asics_1,
          size: [22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataAsics
          },
        },
        {
          id: 2,
          title: { ru: "Асикс", en: "Asics" },
          text: {
            ru: [
              "Материал: кожа",
              "Размер: 33-45",
            ],
            en: [
              "Material: leather",
              "Size: 33-45",
            ],
          },
          price: {
            ru: 14500,
            en: 165
          },
          image: productsImages.asics_2,
          size: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataAsics
          },
        },
        {
          id: 3,
          title: { ru: "Асикс", en: "Asics" },
          text: {
            ru: [
              "Материал: кожа",
              "Размер: 29-45",
            ],
            en: [
              "Material: leather",
              "Size: 29-45",
            ],
          },
          price: {
            ru: 14500,
            en: 165
          },
          image: productsImages.asics_3,
          size: [
            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
          ],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataAsics
          },
        },
        {
          id: 4,
          title: { ru: "Асикс", en: "Asics" },
          text: {
            ru: [
              "Материал: кожа",
              "Размер: 29-45",
            ],
            en: [
              "Material: leather",
              "Size: 29-45",
            ],
          },
          price: {
            ru: 14500,
            en: 165
          },
          image: productsImages.asics_4,
          size: [
            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
          ],
          table: {
            header: tableShoes.headerShoes,
            data: tableShoes.dataAsics
          },
        },
      ],
      image: productsImages.asics,
    },
  ],
  weapon: [
    {
      id: 1,
      title: { ru: "Детский меч Дао", en: "Broadsword" },
      text: {
        ru: [
          "Детский тренировочный меч с фольгированным окончанием.",
          "Размер: 60-80",
        ],
        en: ["Children's training sword with foil end.", "Size: 60-80"],
      },
      price: { ru: 2200, en: 24 },
      image: productsImages.weapons_1,
      DSEC: true,
      size: [60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80]
    },
    {
      id: 2,
      title: { ru: "Детский меч Цзянь", en: "Straight sword" },
      text: {
        ru: [
          "Детский тренировочный меч с фольгированным окончанием.",
          "Размер: 60-80",
        ],
        en: ["Children's training sword with foil end.", "Size: 60-80"],
      },
      price: { ru: 2200, en: 24 },
      image: productsImages.weapons_2,
      DSEC: true,
      size: [60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80]
    },
    {
      id: 3,
      title: { ru: "Наньдао (южный меч)", en: "Nandao (southern sword)" },
      text: {
        ru: [
          "Меч для южных видов ушу с фольгированным окончанием.",
          "Размер: 55-79",
        ],
        en: [
          "Sword for southern types of wushu with a foil ending.",
          "Size: 55-79",
        ],
      },
      price: { ru: 2500, en: 27 },
      image: productsImages.weapons_3,
      DSEC: true,
      size: [55, 57, 59, 61, 63, 65, 67, 69, 71, 73, 75, 77, 79]
    },
    {
      id: 4,
      title: { ru: "Гуйдин Дао", en: "Dayehengtong standart broadsword" },
      text: {
        ru: [
          "Меч дао с ребром жесткости для юниоров и взрослых.",
          "Размер: 68-90",
        ],
        en: [
          "Sword blade is composed of high-quality manganese steel and has stainless steel grip. Product includes black carrying bag as shown.",
          "Size: 68-90",
        ],
      },
      price: { ru: 4800, en: 90 },
      image: productsImages.weapons_4,
      DSEC: true,
      size: [68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90]
    },
    {
      id: 5,
      title: { ru: "Гуйдин Наньдао", en: "Dayehengtong standart southern broadsword" },
      text: {
        ru: [
          "Меч дао дял южных стилей ушу с ребром жесткости для юниоров и взрослых.",
          "Размер: 55-79",
        ],
        en: [
          "Sword blade is composed of high-quality manganese steel and has stainless steel grip.",
          "Product includes black carrying bag as shown.",
          "Size: 55-79",
        ],
      },
      price: { ru: 5700, en: 100 },
      image: productsImages.weapons_5,
      DSEC: true,
      size: [55, 57, 59, 61, 63, 65, 67, 69, 71, 73, 75, 77, 79]
    },
    {
      id: 6,
      title: { ru: "Гуйдин Цзянь", en: "Dayehengtong standart straight sword" },
      text: {
        ru: [
          "Меч цзянь с ребром жесткости для юниоров и взрослых.",
          "Размер: 68-90",
        ],
        en: [
          "Sword blade is composed of high-quality manganese steel.",
          "Product includes scabbard and black carrying bag as shown.",
          "Size: 68-90",
        ],
      },
      price: { ru: 4800, en: 90 },
      image: productsImages.weapons_6,
      DSEC: true,
      size: [68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90]
    },
    {
      id: 7,
      title: { ru: "Палка деревянная для гуньшу/наньгунь", en: "Wooden stick for gunshu/nangun" },
      text: {
        ru: [
          "Гибкая, деревянная, спортивная палка для гуньшу/наньгунь.",
        ],
        en: [
          "Soft, wooden cudgel for training and competiton.",
          "Wholesale available."
        ],
      },
      price: { ru: 1100, en: 12 },
      image: productsImages.weapons_7,
      DSEC: true,
    },
    {
      id: 8,
      title: {
        ru: "Тренировочная пластиковая палка даехэнтун",
        en: "Dayehengtong carbon fiber cudgel",
      },
      text: {
        ru: [
          "Тренировочная палка для гуньшу для взрослых и юниоров.",
          "Размер: 120-180",
        ],
        en: [
          "Composed of a lightweight carbon fiber material, this cudgel is designed to withstand the pressures of contemporary wushu cudgel use.", 
          "Size: 120-180"
        ],
      },
      price: { ru: 4600, en: 175 },
      image: productsImages.weapons_8,
      DSEC: true,
      size: [120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]
    },
    {
      id: 9,
      title: {
        ru: "Тренировочное пластиковое копье даехэнтун",
        en: "Dayehengtong carbon fiber spear",
      },
      text: {
        ru: [
          "Гибкое, удобное, тренировочное пластиковое копье для взрослых и юниоров.",
          "Размер: 170-225",
        ],
        en: [
          "Composed of a lightweight carbon fiber material, this spear is designed to withstand the pressures of contemporary wushu spear use.",
          "Size: 170-225",
        ],
      },
      price: { ru: 6600, en: 220 },
      image: productsImages.weapons_9,
      DSEC: true,
      size: [170, 175, 180, 185, 190, 195, 200, 205, 210, 215, 220, 225]
    },
    {
      id: 10,
      title: { ru: "Наконечник для копья", en: "Spear head" },
      text: {
        ru: ["Металлический наконечник для спортивного копья"],
        en: ["Competition spear head is made from chrome plated metal for a light weight and durable design."],
      },
      price: { ru: 450, en: 6 },
      image: productsImages.weapons_10,
      DSEC: true,
    },
    {
      id: 11,
      title: { ru: "Сань Цзе Гунь", en: "Three section cudgel" },
      text: {
        ru: ["Трех секционная деревянная палка для  ушу-чуаньтун"],
        en: ["Light weight weapon for traditional wushu routines.", "Made from white wax wood."],
      },
      price: { ru: 2500, en: 30 },
      image: productsImages.weapons_11,
      DSEC: true,
    },
    {
      id: 12,
      title: {
        ru: "Шуан бишоу (парные ножи)",
        en: "Double dagger",
      },
      text: {
        ru: ["Парные ножи для традиционного ушу-чуаньтун."],
        en: ["Wrapped handle double dagger for traditional wushu routines."],
      },
      price: { ru: 1300, en: 20 },
      image: productsImages.weapons_12,
      DSEC: true,
    },
    {
      id: 13,
      title: { ru: "Пудао (алебарда)", en: "Pudao (halberd)" },
      text: {
        ru: [
          "Легкая алюминиевая алебарда для ушу-чуаньтун.",
          "Размер: 150-190",
        ],
        en: [
          "Lightweight aluminum halberd for Wushu Chuantong.",
          "Size: 150-190",
        ],
      },
      price: { ru: 3800, en: 41 },
      image: productsImages.weapons_13,
      DSEC: true,
      size: [150, 155, 160, 165, 170, 175, 180, 185, 190]
    },
    {
      id: 14,
      title: {
        ru: "Тайцзицзянь (меч Цзянь для Тайцзи)",
        en: "Taijijian (straight sword for taiji)",
      },
      text: {
        ru: [
          "Меч цзянь для тайцзицюань. В комплекте идут деревянные ножны, чехол и кисть.",
          "Размер: 68-84",
        ],
        en: [
          "Jian sword for taijiquan. The set includes a wooden sheath, case and brush.",
          "Size: 68-84",
        ],
      },
      price: { ru: 5000, en: 54 },
      image: productsImages.weapons_14,
      DSEC: true,
      size: [68, 70, 72, 74, 76, 78, 80, 82, 84]
    },
    {
      id: 15,
      title: {
        ru: "Тайцзидао (меч Дао для Тайцзи)",
        en: "Taiji dao (taiji broadsword)",
      },
      text: {
        ru: [
          "Меч дао для тайцзицюань. В комплекте идут деревянные ножны.",
          "Размер: 68-84",
        ],
        en: [
          "Dao sword for taijiquan. A wooden sheath is included.",
          "Size: 68-84",
        ],
      },
      price: { ru: 4300, en: 47 },
      image: productsImages.weapons_15,
      DSEC: true,
      size: [68, 70, 72, 74, 76, 78, 80, 82, 84]
    },
    {
      id: 16,
      title: {
        ru: "Дуйляньдао (парные мечи для Дуйлянь)",
        en: "Duilian dao (double broadsword for duilian)",
      },
      text: {
        ru: [
          "Парные мечи для вида программы 'дуйлянь' спортивного ушу-таолу и ушу-чуаньтун.",
        ],
        en: [
          "Paired swords for the Duilian program of sports wushu-taolu and wushu-chuantong.",
        ],
      },
      price: { ru: 3500, en: 38 },
      image: productsImages.weapons_16,
      DSEC: true,
    },
    {
      id: 17,
      title: { ru: "Двуручный меч", en: "Two-handed sword" },
      text: {
        ru: [
          "Нержавеющая сталь. В комплекте чехол и ножны. Общая длина меча 136 см.",
        ],
        en: [
          "Stainless steel. Includes case and sheath. The total length of the sword is 136.",
        ],
      },
      price: { ru: 6900, en: 75 },
      image: productsImages.weapons_17,
      DSEC: true,
    },
    {
      id: 18,
      title: { ru: "Металлический Саньцзегунь", en: "Metal three section cudgel" },
      text: {
        ru: [
          "Тренировочное и соревновательное оружие для традиционного ушу-чуаньтун.",
        ],
        en: [
          "Light weight weapon for traditional wushu routines.",
          "Made from stainless steel."
        ],
      },
      price: { ru: 2800, en: 45 },
      image: productsImages.weapons_18,
      DSEC: true,
    },
    {
      id: 19,
      title: {
        ru: "Шуандао (парные мечи Дао)",
        en: "Double broad sword",
      },
      text: {
        ru: [
          "Парное соревновательное оружие для ушу-чуаньтун.",
          "Размер: 60-84",
        ],
        en: ["Wushu chantong competition weapon.", "Size: 60-84"],
      },
      price: { ru: 2800, en: 42 },
      image: productsImages.weapons_19,
      DSEC: true,
      size: [60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84]
    },
    {
      id: 20,
      title: {
        ru: "Шуанцзянь (парные мечи Цзянь)",
        en: "Double straight sword",
      },
      text: {
        ru: [
          "Парное соревновательное оружие для ушу-чуаньтун.",
          "Размер: 60-84",
        ],
        en: ["Wushu chantong competition weapon.", "Size: 60-84"],
      },
      price: { ru: 2800, en: 42 },
      image: productsImages.weapons_20,
      DSEC: true,
      size: [60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84]
    },
    {
      id: 21,
      title: {
        ru: "Цзюцзебянь (девятизвенный Хлыст)",
        en: "Chain whip",
      },
      text: {
        ru: [
          "Соревновательное оружие для ушу-чуаньтун.",
          "Размер: стандартный",
        ],
        en: ["Light chain whip for traditional and contemporary wushu routines.", "Size: standard"],
      },
      price: { ru: 1300, en: 15 },
      image: productsImages.weapons_21,
      DSEC: true,
    },
    {
      id: 22,
      title: {
        ru: "Шуангоу (парные крюки)",
        en: "Shuanggou (double hook)",
      },
      text: {
        ru: [
          "Парное соревновательное оружие для ушу-чуаньтун.",
          "Размер: стандартный",
        ],
        en: [
          "Paired competitive weapons for wushu-chuantong.",
          "Size: standard",
        ],
      },
      price: { ru: 3500, en: 37 },
      image: productsImages.weapons_22,
      DSEC: true,
    },
    {
      id: 23,
      title: {
        ru: "Мяодао (храмовый меч)",
        en: "Мiaodao (temple sword)",
      },
      text: {
        ru: ["Оружие для ушу-чуаньтун.", "Размер: стандартный"],
        en: ["Weapons for Wushu Chuantong.", "Size: standard"],
      },
      price: { ru: 8800, en: 96 },
      image: productsImages.weapons_23,
      DSEC: true,
    },
    {
      id: 24,
      title: {
        ru: "Палка пластиковая Даехэнтун",
        en: "Dayehengtong carbon fiber stick",
      },
      text: {
        ru: [
          "Профессиональная палка для гуньшу и наньгунь для взрослых и юниоров.",
          "Размер: 120-180",
        ],
        en: [
          "Professional stick for gunshu and nangun for adults and juniors.",
          "Size: 120-180",
        ],
      },
      price: { ru: 6600, en: 50 },
      image: productsImages.weapons_24,
      DSEC: true,
      size: [120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]
    },
    {
      id: 25,
      title: {
        ru: "Пластиковое копье Wesing",
        en: "Wesing carbon fiber spear",
      },
      text: {
        ru: [
          "Гибкое, удобное, профессиональное копье для взрослых и юниоров.",
          "Размер: 170-225",
        ],
        en: [
          "Composed of a lightweight carbon fiber material and stainless steel spear head, this cudgel is designed to withstand the pressures of contemporary wushu spear use.",
          "Size: 170-225",
        ],
      },
      price: { ru: 12200, en: 240 },
      image: productsImages.weapons_25,
      DSEC: true,
      size: [170, 175, 180, 185, 190, 195, 200, 205, 210, 215, 220, 225]
    },
    {
      id: 26,
      title: {
        ru: "Пластиковая палка Wesing",
        en: "Wesing carbon fiber cudgel",
      },
      text: {
        ru: [
          "Палка для гуньшу и наньгунь для взрослых и юниоров.",
          "Размер: 120-180",
        ],
        en: [
          "Composed of a lightweight carbon fiber material, this cudgel is designed to withstand the pressures of contemporary wushu cudgel use.",
          "Size: 120-180",
        ],
      },
      price: { ru: 8800, en: 195 },
      image: productsImages.weapons_26,
      DSEC: true,
      size: [120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180]
    },
    {
      id: 27,
      title: {
        ru: "Пластиковое копье Даехэнтун",
        en: "Plastic spear Daehentong",
      },
      text: {
        ru: [
          "Гибкое, удобное, профессиональное копье для взрослых и юниоров.",
          "Размер: 170-225",
        ],
        en: [
          "Flexible, comfortable, professional spear for adults and juniors.",
          "Size: 170-225",
        ],
      },
      price: { ru: 9800, en: 220 },
      image: productsImages.weapons_27,
      DSEC: true,
      size: [170, 175, 180, 185, 190, 195, 200, 205, 210, 215, 220, 225]
    },
  ],
  accessories: [
    {
      id: 1,
      title: { ru: "Кисть для меча Цзянь", en: "Short braided sword tassel" },
      text: {
        ru: [
          "Плетеная кисть для меча цзянь.",
          "Цвета: черный, синий, красный,",
        ],
        en: [
          "Short braided sword tassel.",
          "Colors: black, blue, red",
        ],
      },
      price: { ru: 450, en: 5 },
      image: productsImages.accessories_1,
      DSEC: true,
      color: [colorData[0], colorData[1], colorData[2], colorData[3], colorData[11], colorData[13]]
    },
    {
      id: 2,
      title: {
        ru: "Длинная кисть для меча Цзянь",
        en: "Long braided sword tassel",
      },
      text: {
        ru: [
          "Плетеная кисть для меча цзянь.",
          "Цвета: белый, черный, синий, красный, желтый, розовый",
        ],
        en: [
          "Long braided sword tassel.",
          "Colors: white, black, blue, red, yellow, pink",
        ],
      },
      price: { ru: 900, en: 10 },
      image: productsImages.accessories_2,
      DSEC: true,
      color: [colorData[0], colorData[1], colorData[2], colorData[3], colorData[4], colorData[11]]
    },
    {
      id: 3,
      title: { ru: "Платок для меча Дао", en: "Satin broadsword flags" },
      text: {
        ru: ["Цвет: на выбор"],
        en: ["Color: optional"],
      },
      price: { ru: 350, en: 3.90 },
      image: productsImages.accessories_3,
      DSEC: true,
      color: [colorData[0], colorData[1], colorData[2], colorData[3]]
    },
    {
      id: 4,
      title: { ru: "Комбинированный чехол", en: "Multi weapon carrying bag" },
      text: {
        ru: [
          "Значительно упрощает переноску оружия, так как содержит два отделения для длинного и короткого оружия. Секция для длинного оружия - 1,8 м, секция для короткого оружия подходит для ношения почти всех видов мечей.",
        ],
        en: [
          "It greatly simplifies the carrying of weapons, as it contains two compartments for long and short weapons. The long weapon section is 1.8 m, the short weapon section is suitable for carrying almost all types of swords.",
        ],
      },
      price: { ru: 2100, en: 24 },
      image: productsImages.accessories_4,
      DSEC: true,
    },
    {
      id: 5,
      title: { ru: "Обмотка для рукояти", en: "Wrapping for swords handle" },
      text: {
        ru: [
          "Резиновая обмотка для рукояти меча. Значительно улучшает надежность хвата оружия и не дает ему скользить.",
          "Цвета: черный, розовый, красный, белый, желтый, фиолетовый",
        ],
        en: [
          "Rubber wrapping for the sword handle. Significantly improves the reliability of the weapon's grip and prevents it from slipping.",
          "Colors: black, pink, red, white, yellow, purple",
        ],
      },
      price: { ru: 200, en: 2.50 },
      image: productsImages.accessories_5,
      DSEC: true,
      color: [colorData[0], colorData[1], colorData[3], colorData[4], colorData[6], colorData[11]]
    },
    {
      id: 6,
      title: {
        ru: "Чехол для короткого оружия повышенного качества",
        en: "Sword carrying bag",
      },
      text: {
        ru: [
          "Плотный материал и надежная молния обеспечивают износостойкость и длительную носку. Чехол подходит для переноски мечей дао и цзянь.",
        ],
        en: [
          "Dense material and a reliable zipper provide wear resistance and long-lasting wear. The case is suitable for carrying Dao and Jian swords.",
        ],
      },
      price: { ru: 1300, en: 12 },
      image: productsImages.accessories_6,
      DSEC: true,
    },
    {
      id: 7,
      title: { ru: "Чехол для длинного оружия", en: "Long weapon carrying bag" },
      text: {
        ru: [
          "Чехол длинного оружия. Длина 2,3 м. Отлично подойдет как для копья, так и для палки.",
        ],
        en: [
          "Long weapon bag. Length 2.3 m. Perfect for both a spear and a stick.",
        ],
      },
      price: { ru: 1300, en: 12 },
      image: productsImages.accessories_7,
      DSEC: true,
    },
    {
      id: 8,
      title: { ru: "Борода для копья", en: "Spear tassel" },
      text: {
        ru: [
          "Аксессуар, который используется как украшение для копья. Обязательный элемент для участия в соревнованиях.",
        ],
        en: [
          "An accessory that is used as a decoration for a spear. A required element to participate in competitions.",
        ],
      },
      price: { ru: 200, en: 2.50 },
      image: productsImages.accessories_8,
      DSEC: true,
    },
    {
      id: 9,
      title: { ru: "Веер", en: "Fan" },
      text: {
        ru: [
          "Аксессуар для традиционного ушу. Используется как для тренировок, так и для соревнований.",
        ],
        en: [
          "Accessory for traditional wushu. Used for both training and competition.",
        ],
      },
      price: { ru: 1100, en: 14 },
      image: productsImages.accessories_9,
      DSEC: true,
      color: [colorData[0], colorData[1], colorData[2], colorData[3]]
    },
    {
      id: 10,
      title: { ru: "Южный лев", en: "Southern Lion" },
      text: {
        ru: [
          "Южный лев украсит любое мероприятие в вашей школе ушу. В Китае танцы льва имеют символическое значение. Они отгоняют злых духов и является пожеланием счастья и процветания.",
          "В комплекте: голова льва, накидка, штаны и тапочки-лапы.",
          "Цвета: красный, черный, желтый, зеленый, синий",
        ],
        en: [
          "The Southern Lion will decorate any event at your Wushu school. In China, lion dances have symbolic meaning. They drive away evil spirits and are a wish for happiness and prosperity.",
          "Set consists: a lion's head, cape, pants and shoes-paws.",
          "Colors: red, black, yellow, green, blue",
        ],
      },
      price: { ru: 26000, en: 320 },
      image: productsImages.accessories_10,
      DSEC: true,
      color: [colorData[1], colorData[2], colorData[3], colorData[4], colorData[12]]
    },
    {
      id: 11,
      title: { ru: "Дракон", en: "Dragon" },
      text: {
        ru: [
          "Дракон традиционно символизирует в Китае силу и достоинство. Танцы дракона также имеют символическое значение в Китае. Они часто используются на различных празднествах и фестивалях. Считается, что такие танцы приносят удачу.",
          "В комплекте: голова дракона, палочки для ношения.",
          "Цвета: синий, желтый, зеленый, красный, черный",
        ],
        en: [
          "The dragon traditionally symbolizes strength and dignity in China. Dragon dances also have symbolic meaning in China. They are often used at various celebrations and festivals. It is believed that such dances bring good luck.",
          "Includes: dragon head, carrying sticks.",
          "Colors: blue, yellow, green, red, black",
        ],
      },
      price: { ru: 26000, en: 320 },
      image: productsImages.accessories_11,
      DSEC: true,
      color: [colorData[1], colorData[2], colorData[3], colorData[4], colorData[12]]
    },
  ],
  sanda: [
    {
      id: 1,
      title: { ru: "Форма для ушу-саньда", en: "Wushu sanda uniform" },
      text: {
        ru: [
          "Материал: 100% полиэстер",
          "Размеры: XXXS - 5Xl",
          "Цвет: синий, красный, черный",
        ],
        en: [
          "Material: 100% polyester",
          "Sizes: XXXS - 5Xl",
          "Color: blue, red, black",
        ],
      },
      price: {
        ru: 3000,
        en: 36
      },
      image: productsImages.sanda_1,
      size: [
        "3XS",
        "XXS",
        "XS",
        "S",
        "M",
        "L",
        "XL",
        "XXL",
        "3XL",
        "4XL",
        "5XL",
      ],
      color: [colorData[1], colorData[2], colorData[3]],
    },
    {
      id: 2,
      title: { ru: "Форма для ушу-саньда женская", en: "Women's wushu sanda uniform" },
      text: {
        ru: [
          "Материал: 100% полиэстер",
          "Размеры: XXXS - 5Xl",
          "Цвет: синий, красный, черный",
        ],
        en: [
          "Material: 100% polyester",
          "Sizes: XXXS - 5Xl",
          "Color: blue, red, black",
        ],
      },
      price: {
        ru: 3000,
        en: 36
      },
      image: productsImages.sanda_15,
      size: [
        "3XS",
        "XXS",
        "XS",
        "S",
        "M",
        "L",
        "XL",
        "XXL",
        "3XL",
        "4XL",
        "5XL",
      ],
      color: [colorData[1], colorData[2], colorData[3]],
    },
    {
      id: 3,
      title: { ru: "Жилет защитный мужской", en: "Men's protective vest" },
      text: {
        ru: ["Материал: полиуретан", "Размеры: S - 3XL", "Цвет: красный, синий"],
        en: ["Material: polyester", "Sizes: S - 3XL", "Color: red, blue"],
      },
      price: {
        ru: 6000,
        en: 92
      },
      image: productsImages.sanda_2,
      size: ["S", "M", "L", "XL", "XXL", "3XL"],
      color: [colorData[3], colorData[2]],
    },
    {
      id: 4,
      title: { ru: "Жилет защитный женский", en: "Women's protective vest" },
      text: {
        ru: ["Материал: полиуретан", "Размеры: S - 3XL", "Цвет: красный, синий"],
        en: ["Material: polyester", "Sizes: S - 3XL", "Color: red, blue"],
      },
      price: {
        ru: 6000,
        en: 92
      },
      image: productsImages.sanda_3,
      size: ["S", "M", "L", "XL", "XXL", "3XL"],
      color: [colorData[3], colorData[2]],
    },
    {
      id: 5,
      title: { ru: "Перчатки для ушу-саньда", en: "Gloves for wushu sanda" },
      text: {
        ru: [
          "Материал: кожа и микрофибра",
          "Вес: 8, 10, 12 - OZ",
          "Цвет: синий, красный, черный",
        ],
        en: [
          "Material: leather and microfiber",
          "Weight: 8, 10, 12 - OZ",
          "Color: blue, red, black",
        ],
      },
      price: {
        ru: 5000,
        en: 79
      },
      image: productsImages.sanda_6,
      size: ["8 OZ", "10 OZ", "12 OZ"],
      color: [colorData[1], colorData[2], colorData[3]],
    },
    {
      id: 6,
      title: { ru: "Шлем", en: "Headgear" },
      text: {
        ru: ["Материал: полиуретан", "Размеры: S-XL", "Цвет: красный, синий"],
        en: ["Material: polyester", "Sizes: S-XL", "Color: red, blue"],
      },
      price: {
        ru: 4000,
        en: 65
      },
      image: productsImages.sanda_7,
      size: ["S", "M", "L", "XL"],
      color: [colorData[3], colorData[2]],
    },
    {
      id: 7,
      title: {
        ru: "Шлем cоревновательный",
        en: "Competition headgear",
      },
      text: {
        ru: ["Материал: полиуретан", "Размеры: S-XL", "Цвет: красный, синий"],
        en: ["Material: polyester", "Sizes: S-XL", "Color: red, blue"],
      },
      price: {
        ru: 3200,
        en: 57
      },
      image: productsImages.sanda_9,
      size: ["S", "M", "L", "XL"],
      color: [colorData[3], colorData[2]],
    },
    {
      id: 8,
      title: { ru: "Защита голени и стопы", en: "Shin and foot protection" },
      text: {
        ru: ["Материал: полиуретан", "Размеры: S-XL", "Цвет: красный, синий"],
        en: ["Material: polyester", "Sizes: S-XL", "Color: red, blue"],
      },
      price: {
        ru: 4200,
        en: 68
      },
      image: productsImages.sanda_11,
      size: ["S", "M", "L", "XL"],
      color: [colorData[3], colorData[2]],
    },
    {
      id: 9,
      title: { ru: "Протектор стопы", en: "Foot protector" },
      text: {
        ru: ["Материал: полиуретан", "Размеры: S-2XL", "Цвет: красный, синий"],
        en: ["Material: polyester", "Sizes: S-2XL", "Color: red, blue"],
      },
      price: {
        ru: 1800,
        en: 22
      },
      image: productsImages.sanda_13,
      size: ["S", "M", "L", "XL", "XXL"],
      color: [colorData[3], colorData[2]],
    },
    {
      id: 10,
      title: { ru: "Набор", en: "Sanda competition equipment" },
      text: {
        ru: ["Материал: полиуретан", "Размеры: S-2XL", "Цвет: красный, синий"],
        en: ["Material: polyester", "Sizes: S-2XL", "Color: red, blue"],
      },
      price: {
        ru: 18000,
        en: 254
      },
      image: productsImages.sanda_16,
      size: ["S", "M", "L", "XL", "XXL"],
      color: [colorData[3], colorData[2]],
    }
  ],
  cloth: [
    {
      id: 1,
      title: {
        ru: "Футболка для тренировок и повседневной носки",
        en: "T-shirt for training and everyday wear",
      },
      text: {
        ru: ["Материал: хлопок", "Размеры: 3XS - 2XL"],
        en: ["Material: cotton", "Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_1,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 2,
      title: {
        ru: "Футболка для тренировок и повседневной носки",
        en: "T-shirt for training and everyday wear",
      },
      text: {
        ru: ["Материал: хлопок", "Размеры: 3XS - 2XL"],
        en: ["Material: cotton", "Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_2,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 3,
      title: {
        ru: "Футболка для тренировок и повседневной носки",
        en: "T-shirt for training and everyday wear",
      },
      text: {
        ru: ["Материал: хлопок", "Размеры: 3XS - 2XL"],
        en: ["Material: cotton", "Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_3,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 4,
      title: {
        ru: "Футболка для тренировок и повседневной носки",
        en: "T-shirt for training and everyday wear",
      },
      text: {
        ru: ["Материал: хлопок", "Размеры: 3XS - 2XL"],
        en: ["Material: cotton", "Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_4,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 5,
      title: {
        ru: "Футболка для тренировок и повседневной носки",
        en: "T-shirt for training and everyday wear",
      },
      text: {
        ru: ["Материал: хлопок", "Размеры: 3XS - 2XL"],
        en: ["Material: cotton", "Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_5,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 6,
      title: {
        ru: "Футболка для тренировок и повседневной носки",
        en: "T-shirt for training and everyday wear",
      },
      text: {
        ru: ["Материал: хлопок", "Размеры: 3XS - 2XL"],
        en: ["Material: cotton", "Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_6,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 7,
      title: {
        ru: "Футболка для тренировок и повседневной носки",
        en: "T-shirt for training and everyday wear",
      },
      text: {
        ru: ["Материал: хлопок", "Размеры: 3XS - 2XL"],
        en: ["Material: cotton", "Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_7,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 8,
      title: {
        ru: "Футболка для тренировок и повседневной носки",
        en: "T-shirt for training and everyday wear",
      },
      text: {
        ru: ["Материал: хлопок", "Размеры: 3XS - 2XL"],
        en: ["Material: cotton", "Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_8,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 9,
      title: {
        ru: "Шорты тренировочные для ушу",
        en: "Wushu training shorts",
      },
      text: {
        ru: ["Размеры: 3XS - 2XL"],
        en: ["Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_9,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 10,
      title: {
        ru: "Шорты тренировочные для ушу",
        en: "Wushu training shorts",
      },
      text: {
        ru: ["Размеры: 3XS - 2XL"],
        en: ["Sizes: 3XS - 2XL"],
      },
      price: {
        ru: 2500,
        en: 32
      },
      image: productsImages.cloth_10,
      size: ["3XS", "XXS", "XS", "S", "M", "L", "XL", "XXL"]
    },
    {
      id: 11,
      title: {
        ru: "Штаны для ушу тренировочные Take That",
        en: "Take That Wushu training pants",
      },
      text: {
        ru: ["Материал: 88% полиэфирное волокно, 12% эластан", "Характеристики: легкие, дышащие, тянущиеся, приятные к телу", "Размеры: 2XS - 4XL"],
        en: ["Material: 88% polyester fiber, 12% elastane", "Characteristics: light, breathable, stretchy, pleasant to the body", "Sizes: 2XS - 4XL"],
      },
      price: {
        ru: 4500,
        en: 65
      },
      image: productsImages.cloth_11,
      size: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"]
    },
    {
      id: 12,
      title: {
        ru: "Штаны для ушу тренировочные Take That",
        en: "Take That Wushu training pants",
      },
      text: {
        ru: ["Материал: 88% полиэфирное волокно, 12% эластан", "Характеристики: легкие, дышащие, тянущиеся, приятные к телу", "Размеры: 2XS - 4XL"],
        en: ["Material: 88% polyester fiber, 12% elastane", "Characteristics: light, breathable, stretchy, pleasant to the body", "Sizes: 2XS - 4XL"],
      },
      price: {
        ru: 4500,
        en: 65
      },
      image: productsImages.cloth_12,
      size: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"]
    },
    {
      id: 13,
      title: {
        ru: "Штаны для ушу тренировочные Take That",
        en: "Take That Wushu training pants",
      },
      text: {
        ru: ["Материал: 88% полиэфирное волокно, 12% эластан", "Характеристики: легкие, дышащие, тянущиеся, приятные к телу", "Размеры: 2XS - 4XL"],
        en: ["Material: 88% polyester fiber, 12% elastane", "Characteristics: light, breathable, stretchy, pleasant to the body", "Sizes: 2XS - 4XL"],
      },
      price: {
        ru: 4500,
        en: 65
      },
      image: productsImages.cloth_13,
      size: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"]
    },
    {
      id: 14,
      title: {
        ru: "Штаны для ушу тренировочные Take That",
        en: "Take That Wushu training pants",
      },
      text: {
        ru: ["Материал: 88% полиэфирное волокно, 12% эластан", "Характеристики: легкие, дышащие, тянущиеся, приятные к телу", "Размеры: 2XS - 4XL"],
        en: ["Material: 88% polyester fiber, 12% elastane", "Characteristics: light, breathable, stretchy, pleasant to the body", "Sizes: 2XS - 4XL"],
      },
      price: {
        ru: 4500,
        en: 65
      },
      image: productsImages.cloth_14,
      size: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"]
    },
    {
      id: 15,
      title: {
        ru: "Штаны для ушу тренировочные Take That",
        en: "Take That Wushu training pants",
      },
      text: {
        ru: ["Материал: 88% полиэфирное волокно, 12% эластан", "Характеристики: легкие, дышащие, тянущиеся, приятные к телу", "Размеры: 2XS - 4XL"],
        en: ["Material: 88% polyester fiber, 12% elastane", "Characteristics: light, breathable, stretchy, pleasant to the body", "Sizes: 2XS - 4XL"],
      },
      price: {
        ru: 4500,
        en: 65
      },
      image: productsImages.cloth_15,
      size: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"]
    },
    {
      id: 16,
      title: {
        ru: "Тренировочные штаны Li-Ning",
        en: "Li-Ning training pants",
      },
      text: {
        ru: ["Материал: вискоза", "Размеры: 150 - 190", "Цвета: черный, синий, красный, фиолетовый, серый"],
        en: ["Material: viscose", "Sizes: 150 - 190", "Colors: black, blue, red, purple, gray"],
      },
      price: {
        ru: 5000,
        en: 65
      },
      image: productsImages.cloth_16,
      size: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"],
      color: [colorData[1], colorData[2], colorData[3], colorData[6], colorData[7]]
    },
  ],
  "carpets-platforms": [
    {
      id: 1,
      title: {
        ru: "Помост для саньда",
        en: "Sanda platform",
      },
      text: {
        ru: ["Материал: вискоза"],
        en: ["Material: viscose"],
      },
      price: { ru: 2000000 , en: 21600 },
      image: productsImages.carpetPlatforms_1,
    },
    {
      id: 2,
      title: {
        ru: "Ковер для ушу-таолу",
        en: "Carpet for wushu taolu",
      },
      text: {
        ru: ["Материал: вискоза"],
        en: ["Material: viscose"],
      },
      price: { ru: 3500000, en: 37800 },
      image: productsImages.carpetPlatforms_2,
    },
  ]
};

export default productsD;
