import React from 'react'
import i18next from 'i18next'

import './Advantages.css'
import AdvantagesItem from '../../components/AdvantagesItem/AdvantagesItem'
import { useTranslation } from 'react-i18next'

const Advantages = () => {
    const { t } = useTranslation()
    const advantagesItemsData = [
        {
            id: 1,
            text: { 
                ru: "12 лет организовываем поставки для ушуистов, контролируя качество непосредственно в Китае.", 
                en: "We have been organizing supplies for wushu players for 12 years, controlling quality directly in China."
            }
        },
        {
            id: 2,
            text: { 
                ru: "Глубоко понимаем потребности наших клиентов, так как сами много лет профессионально занимаемся ушу. Поставляем то, что действительно нужно для успеха.", 
                en: "We deeply understand the needs of our clients, since we ourselves have been practicing wushu professionally for many years. We deliver what you really need for success"
            }
        },
        {
            id: 3,
            text: {
                ru: "До введения в 2019 ФУР однотонного стандарта на соревнованиях в наших костюмах выступало 80% всех спортсменов.",
                en: "Currently, we are one of the leading players in the Wushu market, working with athletes from various national teams, including those from Russia, USA, Canada, Italy, France, Spain and Mexico, among others."
            }
        }

    ]
  return (
    <div className='advantages'>
        <div className="container">
            <div className="title">{t('advantages__title')}<span>{t('advantages__title__span')}</span></div>
            <div className="advantages__items">
                {advantagesItemsData.map((item) => <AdvantagesItem key={item.id} title={item.id} text={i18next.language === 'ru' ? item.text.ru : item.text.en}/>)}
            </div>
        </div>
    </div>
  )
}

export default Advantages