import React from 'react'

import './FAQItem.css'
import { images } from '../../constants'
const FAQItem = ({ title, text }) => {
  return (
    <div className='faq-item'>
        <div className="title"><img src={images.faqItem__icon} alt="faq-icon" />{title}</div>
        <div className="text">{text}</div>
    </div>
  )
}

export default FAQItem